import InternalDal from "app/utils/InternalDal";
import { UserProfileUpdate } from "./types";

class UserDal {
  private readonly dal: InternalDal;
  private readonly authUrl: string;

  constructor(apiUrl?: string) {
    this.dal = new InternalDal(apiUrl);
    this.authUrl = `${this.dal.basePublicUrl}/auth`;
  }

  logIn(username: string, password: string) {
    const url = `${this.authUrl}/login/`;
    const data = {
      username,
      password,
    };

    return this.dal.poster(url, data, "logIn", false, false);
  }

  logOut() {
    const url = `${this.authUrl}/logout/`;
    const data = {};

    return this.dal.poster(url, data, "logOut");
  }

  updatePassword(oldPassword: string, newPassword1: string, newPassword2: string) {
    const url = `${this.authUrl}/password/change/`;
    const data = {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2,
    };

    return this.dal.poster(url, data, "updatePassword");
  }

  getUser(schemaName = this.dal.authSchemaName) {
    const url = this.dal.makePublicUrl("auth/user", schemaName);
    return this.dal.getter(url, "getUser");
  }

  getUserProfile(userProfilePk: number) {
    const url = this.dal.makePublicUrl(`user-profiles/${userProfilePk}`);
    return this.dal.getter(url, "getUserProfile");
  }

  patchUserProfile(userProfile: UserProfileUpdate) {
    const userProfilePk = userProfile.pk;
    const url = this.dal.makePublicUrl(`user-profiles/${userProfilePk}`);
    return this.dal.patcher(url, userProfile, "patchUserProfile");
  }
}

export default UserDal;
