import { AppContentWrapper } from "app/core/components/AppContent";
import { AppTopNavbarWrapper } from "app/core/components/AppTopNavbar";
import { openSentryReportDialog } from "app/core/sentry";
import Panel from "app/common/components/Panel";
import history from "app/history";
import { sentryDSN } from "app/process";

//
// Application content wrapper
//
const columnFlexWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  width: "100%",
  height: "100%",
};

const detailsStyle = { whiteSpace: "pre-wrap" };

const AppErrorFallbackDebugDetails = ({ error, errorInfo }) => (
  <details style={detailsStyle}>
    <pre>
      {error && error.toString()}
      <br />
      {errorInfo.componentStack}
    </pre>
  </details>
);

const AppErrorFallbackPanel = ({ error, errorInfo, debugMode }) => (
  <Panel>
    <h2>Oops! Something went wrong...</h2>
    An error occurred and the YouPlan-team has been notified. <br />
    In the mean time you can try to
    <br />
    <ul style={{ paddingLeft: 30, paddingTop: 15, listStyle: "auto" }}>
      <li>
        <a onClick={() => window.location.reload()}>reload</a>&nbsp;the current page
      </li>
      <li>
        <a onClick={() => history.goBack()}>go back</a>&nbsp;to the previous page
      </li>
    </ul>
    {sentryDSN && (
      <>
        <br />
        <br />
        <h5>Feedback</h5>
        To help us improve, please <a onClick={openSentryReportDialog}>report feedback</a> on what happened.
      </>
    )}
    {debugMode && (
      <>
        <br />
        <br />
        <h5>Error Debug</h5>
        <AppErrorFallbackDebugDetails error={error} errorInfo={errorInfo} />
      </>
    )}
  </Panel>
);

const AppErrorFallBack = ({ error, errorInfo, debugMode }) => (
  <div style={columnFlexWrapperStyle}>
    <AppTopNavbarWrapper />
    <AppContentWrapper>
      <AppErrorFallbackPanel error={error} errorInfo={errorInfo} debugMode={debugMode} />
    </AppContentWrapper>
  </div>
);

export default AppErrorFallBack;
