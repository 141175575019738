import {
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiListGroupItem,
  EuiShowFor,
} from "@elastic/eui";
import sidebarLogo from "assets/img/youplan-square.png";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import AppSidebar from "app/core/components/AppSidebar";
import useLocalStorageState from "app/common/hooks/useLocalStorageState";

const sidebarLogoStyle = {
  minHeight: 49,
  height: 49,
  backgroundColor: "#222",
};

const AppLogo = () => <img src={sidebarLogo} className="AppLogo" alt="YouPlan" />;

const AppLogoSidebarHeader = () => (
  <div style={sidebarLogoStyle}>
    <Link to={"/"} style={{ padding: 0 }}>
      <EuiHeader className="EuiHeaderNav" theme={"dark"}>
        <div className="euiHeaderLogo">
          <EuiIcon className="euiHeaderLogo__icon" size="l" type={AppLogo} />
          <span className="euiHeaderLogo__text">YouPlan</span>
        </div>
      </EuiHeader>
    </Link>
  </div>
);

const labelUndockNav = <FormattedMessage id="navigation.undock" />;
const labelDockNav = <FormattedMessage id="navigation.dock" />;

const useSidebar = () => {
  const [navIsDocked, setNavIsDocked] = useLocalStorageState("navIsDocked", false);
  const [navIsOpen, setNavIsOpen] = useLocalStorageState("navIsOpen", true);

  const toggleDocked = useCallback(() => setNavIsDocked((isDocked) => !isDocked), [setNavIsDocked]);
  const toggleNav = useCallback(() => setNavIsOpen((isOpen) => !isOpen), [setNavIsOpen]);
  const closeNav = useCallback(() => setNavIsOpen(false), [setNavIsOpen]);

  return {
    navIsOpen,
    navIsDocked,
    closeNav,
    toggleNav,
    toggleDocked,
  };
};

const AppSidebarCollapsible = () => {
  const { navIsOpen, navIsDocked, toggleDocked, toggleNav, closeNav } = useSidebar();

  const btnToggleNav = (
    <EuiHeaderSectionItemButton aria-label="Toggle main navigation" onClick={toggleNav}>
      <EuiIcon type={"menu"} size="m" aria-hidden="true" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiCollapsibleNav
      maskProps={{ headerZindexLocation: "below" }}
      repositionOnScroll
      anchorPosition="downRight"
      aria-label="Main navigation"
      isOpen={navIsOpen}
      isDocked={navIsDocked}
      size={250}
      style={{ borderRight: 0 }}
      button={btnToggleNav}
      onClose={closeNav}
    >
      <EuiFlexItem className="eui-yScroll">
        <AppLogoSidebarHeader />
        <AppSidebar />

        {/* Docking button only for larger screens that can support it*/}
        <EuiShowFor sizes={["l", "xl"]}>
          <EuiCollapsibleNavGroup>
            <EuiListGroupItem
              size="xs"
              color="subdued"
              label={navIsDocked ? labelUndockNav : labelDockNav}
              onClick={toggleDocked}
              iconType={navIsDocked ? "lockOpen" : "lock"}
            />
          </EuiCollapsibleNavGroup>
        </EuiShowFor>
      </EuiFlexItem>
    </EuiCollapsibleNav>
  );
};

export default AppSidebarCollapsible;
