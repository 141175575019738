import { fromJS } from "immutable";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { keyArrToClearHandler, keyArrToLoadDetailedHandler } from "app/utils/redux/reducerHandlers";
import { actionTypes } from "./actions";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
  matrices: {
    all: notLoadedMap,
    detailed: notLoadedMap,
  },
  resets: {
    detailed: notLoadedMap,
  },
  transitions: {
    detailed: notLoadedMap,
  },
});

function handleLoadSimpleResourceStates(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("all", isLoadingMap);
  }
  if (action.status === "done") {
    const resourceStatesJS = action.payload.body.results;
    const resourceStatesMap = arrayToPkMap(resourceStatesJS, "resourceState");
    state = state.set("all", resourceStatesMap);
  }
  return state;
}

function handleClearSimpleResourceStates(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("all", notLoadedMap);
  }
  return state;
}

function handleLoadSimpleResourceStateMatrices(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.setIn(["matrices", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const matricesJS = action.payload.body.results;
    const matricesMap = arrayToPkMap(matricesJS, "resourceStateMatrix");
    state = state.setIn(["matrices", "all"], matricesMap);
  }
  return state;
}

function handleClearSimpleResourceStateMatrices(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.setIn(["matrices", "all"], notLoadedMap);
  }
  return state;
}

function resourceStatesReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_RESOURCE_STATES]: handleLoadSimpleResourceStates,
    [actionTypes.CLEAR_SIMPLE_RESOURCE_STATES]: handleClearSimpleResourceStates,
    [actionTypes.LOAD_DETAILED_RESOURCE_STATE]: keyArrToLoadDetailedHandler(["detailed"]),
    [actionTypes.CLEAR_DETAILED_RESOURCE_STATE]: keyArrToClearHandler(["detailed"]),

    [actionTypes.LOAD_SIMPLE_RESOURCE_STATE_MATRICES]: handleLoadSimpleResourceStateMatrices,
    [actionTypes.CLEAR_SIMPLE_RESOURCE_STATE_MATRICES]: handleClearSimpleResourceStateMatrices,
    [actionTypes.LOAD_DETAILED_RESOURCE_STATE_MATRIX]: keyArrToLoadDetailedHandler(["matrices", "detailed"]),
    [actionTypes.CLEAR_DETAILED_RESOURCE_STATE_MATRIX]: keyArrToClearHandler(["matrices", "detailed"]),

    [actionTypes.LOAD_DETAILED_RESOURCE_STATE_RESET]: keyArrToLoadDetailedHandler(["resets", "detailed"]),
    [actionTypes.CLEAR_DETAILED_RESOURCE_STATE_RESET]: keyArrToClearHandler(["resets", "detailed"]),

    [actionTypes.LOAD_DETAILED_RESOURCE_STATE_TRANSITION]: keyArrToLoadDetailedHandler(["transitions", "detailed"]),
    [actionTypes.CLEAR_DETAILED_RESOURCE_STATE_TRANSITION]: keyArrToClearHandler(["transitions", "detailed"]),
  });
}

export default resourceStatesReducer;
