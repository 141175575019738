import { ReactNode, RefObject } from "react";

interface ExternalLinkProps {
  linkRef?: RefObject<HTMLAnchorElement>;
  children?: ReactNode;
  href: string;
  label?: string;
}

interface ExternalLinkLabelWithIconProps {
  label?: string;
}

const ExternalLinkIcon = () => <i className="fa fa-external-link" aria-hidden="true" />;

const ExternalLinkLabelWithIcon = ({ label }: ExternalLinkLabelWithIconProps) => {
  const icon = <ExternalLinkIcon />;
  return label ? (
    <>
      {label}&nbsp;{icon}
    </>
  ) : (
    icon
  );
};

const ExternalLink = ({ linkRef, children, href, label = "", ...otherProps }: ExternalLinkProps) => (
  <a ref={linkRef} rel="noopener noreferrer" target="_blank" href={href} {...otherProps}>
    {children ? children : <ExternalLinkLabelWithIcon label={label} />}
  </a>
);

export default ExternalLink;
