import { useEffect, useMemo, useState } from "react";
import { safeParseJSON } from "app/utils/json";

const useLocalStorageState = (key, defaultValue = null) => {
  const initialValue = useMemo(() => safeParseJSON(localStorage.getItem(key)) ?? defaultValue, [key, defaultValue]);
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorageState;
