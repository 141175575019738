import Loadable from "react-loadable";
import BusyFeedbackPage from "app/common/components/BusyFeedbackPage";
import { CustomSwitch } from "app/utils/routing";
import { LogIn, LogOut } from "app/features/auth";
import TenantAuth from "app/features/tenants/components/TenantAuth";

// TODO
//  Does this lazy loading make sense here? It should probably be put in the LoggedInUserSwitch component.

const LazyLoadingLoggedInUserSwitch = Loadable({
  loader: () => import("app/core/components/LoggedInUserSwitch"),
  loading: BusyFeedbackPage,
});

const appContentRoutes = [
  {
    path: "login",
    component: LogIn,
  },
  {
    path: "logout",
    component: LogOut,
  },
  {
    path: ":tenantId/login",
    component: TenantAuth,
  },
];
const appContentIndexRoute = { component: LazyLoadingLoggedInUserSwitch };

const AppContentSwitch = () => <CustomSwitch routes={appContentRoutes} indexRoute={appContentIndexRoute} />;

export default AppContentSwitch;
