import { createContext, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "app/utils/momentLocalized";
import {
  getUser,
  getUserDisplayName,
  getUserHasTenant,
  getUserIsAllowedToDebug,
  getUserIsInDebugMode,
  getUserIsLoaded,
  getUserProfile,
  getUserProfileLocale,
} from "app/features/users/selectors";

const useSetLocale = () => {
  const locale = useSelector(getUserProfileLocale);

  useEffect(() => {
    if (!locale) return;

    // Set locale for humanize-duration and moment.
    window.locale = locale;
    moment.locale(locale);
  }, [locale]);

  return locale;
};

const AppUserContext = createContext({
  user: null,
  userLoaded: false,
  userProfile: {},
  userHasTenant: true,
  userIsAllowedToDebug: false,

  debugMode: false,
  locale: "en",
});

export const useAppUserContext = () => {
  const context = useContext(AppUserContext);
  if (context === undefined) {
    throw new Error("useAppUserContext must be used within a AppUserContext.Provider");
  }

  return context;
};

const AppUserProvider = ({ children }) => {
  const locale = useSetLocale();

  const contextValue = {
    user: useSelector(getUser),
    userLoaded: useSelector(getUserIsLoaded),
    userProfile: useSelector(getUserProfile),
    userHasTenant: useSelector(getUserHasTenant),
    userIsAllowedToDebug: useSelector(getUserIsAllowedToDebug),
    userDisplayName: useSelector(getUserDisplayName),

    debugMode: useSelector(getUserIsInDebugMode),
    locale,
  };

  return <AppUserContext.Provider value={contextValue}>{children}</AppUserContext.Provider>;
};

export default AppUserProvider;
