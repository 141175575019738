import { FormattedMessage } from "react-intl";

export type PanelType = "resources" | "resourcesWork" | "work" | "workResources";

export const PANEL_TYPE: Record<string, PanelType> = {
  RESOURCES: "resources",
  RESOURCES_WORK: "resourcesWork",
  WORK: "work",
  WORK_RESOURCES: "workResources",
};
export const panelOptions = [
  {
    value: PANEL_TYPE.WORK_RESOURCES,
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.workResources" />,
  },
  {
    value: PANEL_TYPE.RESOURCES_WORK,
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.resourcesWork" />,
  },
  {
    value: PANEL_TYPE.RESOURCES,
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.resources" />,
  },
  {
    value: PANEL_TYPE.WORK,
    label: <FormattedMessage id="PlanBoardToolbar.panelOptions.work" />,
  },
];
export const panelOptionValues = panelOptions.map((o) => o.value);

export const intervalTypeOptions = [
  {
    value: "day",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.day" />,
  },
  {
    value: "week",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.week" />,
  },
  {
    value: "workweek",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.workweek" />,
  },
  {
    value: "month",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.month" />,
  },
  {
    value: "year",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.year" />,
  },
  {
    value: "custom",
    label: <FormattedMessage id="PlanBoardToolbar.intervalOptions.custom" />,
  },
];

export const intervalTypeScopeOption = {
  value: "scope",
  label: "Scope",
};

export const intervalTypeValues = intervalTypeOptions.map((o) => o.value);

export default {};
