import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { getUserProfileLocale } from "app/features/users/selectors";
import localeData from "app/locales/index";

const getNavigatorLanguage = () => {
  // Define user's language. Different browsers have the user locale defined
  // on different fields on the `navigator` object, so we make sure to account
  // for these different by checking all of them
  return (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
};

export const useLocale = () => {
  const userProfileLanguage = useSelector(getUserProfileLocale);
  return userProfileLanguage || getNavigatorLanguage();
};

export const useLocaleNoRegionCode = () => {
  // Strips the region code from the locale (example: en-us -> en)
  const language = useLocale();
  return language.toLowerCase().split(/[_-]+/)[0];
};

const AppIntlProvider = ({ children }) => {
  const locale = useLocale();
  const localeNoRegionCode = useLocaleNoRegionCode();

  // Try full locale, try locale without region code, fallback to 'en'
  const messages = localeData[locale] || localeData[localeNoRegionCode] || localeData.en;

  return (
    <IntlProvider key={locale} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
