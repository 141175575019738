import { fromJS } from "immutable";
import { v1 as uuid1 } from "uuid";
import { emptyList } from "app/utils/constants";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes as userActionTypes } from "app/features/users/actions";
import { actionTypes } from "./actions";

const initialState = fromJS({
  messages: [],
});

function handleAddErrorMsg(prevState, errorMsg) {
  let state = prevState;

  const existingMessages = state.get("messages");

  const e = fromJS({
    id: uuid1(),
    message: errorMsg,
    type: "error",
    alertId: null,
  });

  const errorMsgExists = existingMessages.find((msg) => msg.get("message") === errorMsg && msg.get("type") === "error");
  if (!errorMsgExists) {
    const messages = state.get("messages").push(e);
    state = state.set("messages", messages);
  }

  return state;
}

function handleErrorFromActionPayloadPromise(prevState, action) {
  let state = prevState;

  const { status, payload } = action;
  if (status === "error") {
    const { errorInfo } = payload;

    // If we are trying to get the user and a not authenticated status is returned we just redirect
    // to the login-page, the error recording is not necessary!
    const isUserNotAuthenticatedError = errorInfo && (errorInfo.status === 403 || errorInfo.status === 401);
    const isUnableToLogIn = errorInfo?.status === 400 && action.type === userActionTypes.LOGIN;

    if (!(isUserNotAuthenticatedError || isUnableToLogIn)) {
      state = handleAddErrorFromPromise(state, action);
    }
  }

  return state;
}

function handleAddErrorFromPromise(prevState, action) {
  // By passing in a caught error as payload, this should automatically add an error to the app, through
  // the handleError reducer handler.
  let state = prevState;
  const { payload } = action;
  const { errorInfo } = payload;

  const errorMsg = errorInfo?.text || payload?.toString() || "UNKNOWN ERROR";
  state = handleAddErrorMsg(state, errorMsg);
  return state;
}

function handleDismissMessage(prevState, action) {
  let state = prevState;

  const { payload: messageId } = action;

  const messages = state.get("messages").filterNot((m) => m.get("id") === messageId);
  state = state.set("messages", messages);
  return state;
}

function handleDismissAllMessages(prevState, _action) {
  let state = prevState;
  state = state.set("messages", emptyList);
  return state;
}

function messagesReducer(prevState = initialState, action) {
  let state = prevState;
  state = handleErrorFromActionPayloadPromise(state, action);

  return reduceWithHandlers(state, action, {
    [actionTypes.ADD_ERROR_FROM_PROMISE]: handleAddErrorFromPromise,
    [actionTypes.DISMISS_MESSAGE]: handleDismissMessage,
    [actionTypes.DISMISS_ALL_MESSAGES]: handleDismissAllMessages,
  });
}

export default messagesReducer;
