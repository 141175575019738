import { createSelector } from "reselect";
import { safeToMoment } from "app/utils/momentLocalized";
import { lastSnapshotIsLoadingKey, lastSnapshotKey, noSnapshotFoundKey } from "app/features/snapshots/reducer";

const getRootSnapshots = (state) => state.app.get("snapshots");

export const getLastCompletedSnapshotRoot = createSelector([getRootSnapshots], (rootSnapshots) =>
  rootSnapshots.get(lastSnapshotKey),
);

const createRootSelector = (combiner) => createSelector([getLastCompletedSnapshotRoot], combiner);
const createLastCompletedSnapshotSelector = (combiner) => createSelector([getLastCompletedSnapshot], combiner);

// ROOT
export const getLastCompletedSnapshotDto = createRootSelector((root) => root?.get("snapshotDto"));

export const getLastCompletedSnapshot = createRootSelector((root) => root?.get("snapshotInfo"));

export const getIsLoadingLastCompletedSnapshot = createSelector([getRootSnapshots], (s) =>
  s.get(lastSnapshotIsLoadingKey),
);

export const getNoSnapshotFound = createSelector([getRootSnapshots], (s) => s.get(noSnapshotFoundKey));

// Loaded snapshot
export const getLastCompletedSnapshotScopePk = createLastCompletedSnapshotSelector((lastCompletedSnapshot) =>
  lastCompletedSnapshot ? lastCompletedSnapshot.get("scope") : -1,
);

export const getLastCompletedSnapshotScopeStart = createLastCompletedSnapshotSelector((lastCompletedSnapshot) =>
  safeToMoment(lastCompletedSnapshot?.get("dt_scope_start")),
);

export const getLastCompletedSnapshotScopeEnd = createLastCompletedSnapshotSelector((lastCompletedSnapshot) =>
  safeToMoment(lastCompletedSnapshot?.get("dt_scope_end")),
);

export const getLastCompletedSnapshotPk = createLastCompletedSnapshotSelector((lastCompletedSnapshot) =>
  lastCompletedSnapshot?.get("pk"),
);

export const getLastCompletedSnapshotIsOutDated = createLastCompletedSnapshotSelector((lastCompletedSnapshot) =>
  lastCompletedSnapshot?.get("is_outdated"),
);

export const getLastCompletedSnapshotIsMissingChanges = createLastCompletedSnapshotSelector((lastCompletedSnapshot) =>
  lastCompletedSnapshot?.get("is_missing_changes"),
);

export const getSnapshotNextRun = createLastCompletedSnapshotSelector((lastCompletedSnapshot) =>
  safeToMoment(lastCompletedSnapshot?.get("next_run")),
);
