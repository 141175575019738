import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import superagent, { Request } from "superagent";
import { requestToPromise } from "app/utils/dal";
import { getUserFirebaseUser, getUserTenantSchemaName } from "app/features/users/selectors";
import store from "app/store";

// TODO must be JSON serializable data
export type RequestData = string | object;

const defaultApiUrl = "/api";
const isNotTenantSchemaName = (schemaName?: string) => !schemaName || schemaName === "" || schemaName === "public";

export const makeUrlTenantAware = (url: string, schemaName: string, apiUrl = defaultApiUrl) => {
  const baseUrl = isNotTenantSchemaName(schemaName) ? `${apiUrl}/internal` : `${apiUrl}/internal/${schemaName}`;
  return url === "" ? baseUrl : `${baseUrl}/${url}`;
};

export const useTenantAwareInternalApiUrl = (url: string, apiUrl = defaultApiUrl) => {
  const schemaName = useSelector(getUserTenantSchemaName);
  return makeUrlTenantAware(url, schemaName, apiUrl);
};

class InternalDal {
  public readonly apiUrl: string;
  public readonly tenantSchemaName: string;
  public readonly basePublicUrl: string;
  public readonly baseTenantUrl: string;
  public authHeader?: string; // TODO fix nicely, not readonly because might be reset
  public readonly authSchemaName?: string;
  public readonly hasFirebaseUser: boolean;
  public readonly hasFirebaseUserOrSession: boolean;
  public readonly hasSession: boolean;
  public readonly csrfToken: string;

  constructor(apiUrl = defaultApiUrl) {
    const reduxState = store.getState();

    // Urls
    this.apiUrl = apiUrl;
    this.tenantSchemaName = getUserTenantSchemaName(reduxState);
    this.basePublicUrl = apiUrl;
    this.baseTenantUrl = this.makeTenantAwareApiUrl("");

    // CSRF protection
    this.csrfToken = Cookies.get("csrftoken") || "";

    // Session
    this.hasSession = Cookies.get("sessionisactive") === "1";

    // Firebase auth
    const firebaseUser = getUserFirebaseUser(reduxState);
    this.authHeader = firebaseUser ? `Bearer ${firebaseUser.accessToken}` : undefined;
    this.authSchemaName = firebaseUser ? firebaseUser.schemaName : undefined;

    this.hasFirebaseUser = !!firebaseUser;
    this.hasFirebaseUserOrSession = this.hasFirebaseUser || this.hasSession;
  }

  makeTenantAwareApiUrl(url: string, schemaName = this.tenantSchemaName) {
    return makeUrlTenantAware(url, schemaName, this.apiUrl);
  }

  makePublicUrl(url: string, schemaName = this.authSchemaName) {
    // If we are using authTokens through firebase then the user needs to be authenticated based on the schemaName.
    // Currently we handle this by having exposed auth endpoints under /{tenant}/... as well.
    // TODO
    //  - we should be able to handle this more elegantly
    //  - it's ugly that basePublicUrl does not have an apiVersion ('internal', 'v1', ...)
    return isNotTenantSchemaName(schemaName)
      ? `${this.basePublicUrl}/${url}/`
      : makeUrlTenantAware(url, schemaName!, this.apiUrl);
  }

  setHeaders(request: Request, setAuthHeader = true) {
    request = request.set("X-CSRFToken", this.csrfToken);
    if (setAuthHeader && this.authHeader) {
      request = request.set("Authorization", this.authHeader);
    }
    return request;
  }

  getter(url: string, reqName: string) {
    let request = superagent.get(url);
    request = this.setHeaders(request);
    return requestToPromise(request, reqName);
  }

  putter(url: string, data: RequestData, reqName: string) {
    let request = superagent.put(url);
    request = this.setHeaders(request);
    request = request.send(data);
    return requestToPromise(request, reqName);
  }

  poster(url: string, data: RequestData, reqName: string, blob = false, setAuthHeader = true) {
    // TODO blob, setAuthHeader should be part of some "opts" object
    let request = superagent.post(url);
    request = this.setHeaders(request, setAuthHeader);
    request = request.send(data);
    if (blob) {
      request = request.responseType("blob");
    }
    return requestToPromise(request, reqName);
  }

  deleter(url: string, reqName: string) {
    let request = superagent.del(url);
    request = this.setHeaders(request);
    return requestToPromise(request, reqName);
  }

  patcher(url: string, data: RequestData, reqName: string) {
    let request = superagent.patch(url);
    request = this.setHeaders(request);
    request = request.send(data);
    return requestToPromise(request, reqName);
  }
}

export default InternalDal;
