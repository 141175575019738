import { cloneElement, useCallback } from "react";
import ErrorList from "app/common/forms/ErrorList";
import HelpBlock from "app/common/forms/HelpBlock";
import Label from "app/common/forms/Label";
import { getFormClassNames } from "app/common/forms/utils";
import { emptyList } from "app/utils/constants";

const useHandleFieldChange = ({ onChange, onFieldChange, name }) => {
  // Handlers
  // Change payload is an object like {fieldName:value}
  // so that a function that takes multiple changes can also be directly used

  const handleChangeValue = useCallback(
    (value) => {
      if (onChange) {
        onChange({ [name]: value });
      }
      if (onFieldChange) {
        onFieldChange(value);
      }
    },
    [onChange, onFieldChange, name],
  );

  const handleChange = useCallback((e) => handleChangeValue(e.target.value), [handleChangeValue]);
  return handleChange;
};

const Field = ({
  inputElement,
  inputName,
  labelElement: LabelElement = Label,
  errorListElement: ErrorListElement = ErrorList,
  name,
  label = null,
  help = null,
  value = "",
  disabled = false,
  errors = emptyList,
  onChange,
  onFieldChange,
  classNames: classNamesFromProps = ["form-group"],
}) => {
  const handleChange = useHandleFieldChange({ onChange, onFieldChange, name });

  // TODO when we want to use a different inputElement:
  // i) we should create a base Field component
  // ii) rename this to TextField component
  inputName = inputName || `${name}_input`;
  let inputElementToRender;
  if (inputElement) {
    const extraProps = {
      inputName,
      onChange: handleChange,
      disabled,
      value,
    };
    inputElementToRender = cloneElement(inputElement, extraProps);
  } else {
    inputElementToRender = (
      <input
        id={inputName}
        name={inputName}
        className="form-control"
        type="text"
        value={value !== null ? value : ""}
        disabled={disabled}
        onChange={handleChange}
      />
    );
  }

  const labelPart = label !== null ? <LabelElement inputName={inputName} label={label} /> : null;
  const className = getFormClassNames(errors, classNamesFromProps);

  return (
    <div className={className}>
      {labelPart}
      {inputElementToRender}
      <HelpBlock text={help} />
      <ErrorListElement errors={errors} />
    </div>
  );
};

export default Field;
