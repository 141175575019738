import { getEmptyPromise } from "app/utils/dal";
import LabelsDal from "app/features/labels/dal";

export const actionTypes = {
  CREATE_LABEL: "labels/CREATE_LABEL",
  UPDATE_LABEL: "labels/UPDATE_LABEL",
  DELETE_LABEL: "labels/DELETE_LABEL",
  LOAD_SIMPLE_LABELS: "labels/LOAD_SIMPLE_LABELS",
  CLEAR_SIMPLE_LABELS: "labels/CLEAR_SIMPLE_LABELS",
  LOAD_DETAILED_LABEL: "labels/LOAD_DETAILED_LABEL",
  CLEAR_DETAILED_LABEL: "labels/CLEAR_DETAILED_LABEL",
};

const actionCreators = {
  loadSimpleLabels() {
    const dal = new LabelsDal();
    const actionPromise = dal.getSimpleLabels();
    return {
      type: actionTypes.LOAD_SIMPLE_LABELS,
      payload: actionPromise,
    };
  },

  clearSimpleLabels() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_SIMPLE_LABELS,
      payload: actionPromise,
    };
  },

  loadDetailedLabel(labelPk) {
    const dal = new LabelsDal();
    const actionPromise = dal.getDetailedLabel(labelPk);
    return {
      type: actionTypes.LOAD_DETAILED_LABEL,
      payload: actionPromise,
    };
  },

  clearDetailedLabel() {
    const actionPromise = getEmptyPromise();
    return {
      type: actionTypes.CLEAR_DETAILED_LABEL,
      payload: actionPromise,
    };
  },

  createLabel(label) {
    const dal = new LabelsDal();
    const actionPromise = dal.createLabel(label);
    return {
      type: actionTypes.CREATE_LABEL,
      payload: actionPromise,
    };
  },

  updateLabel(label) {
    const dal = new LabelsDal();
    const actionPromise = dal.putLabel(label);
    return {
      type: actionTypes.UPDATE_LABEL,
      payload: actionPromise,
    };
  },

  deleteLabel(labelPk) {
    const dal = new LabelsDal();
    const actionPromise = dal.deleteLabel(labelPk);
    return {
      type: actionTypes.DELETE_LABEL,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
