//
// Main frontend bundle entry point
//
// NOTE Babel polyfill should be on top of the first entry loaded!!
// import '@babel/polyfill';
import initialiseApp from "app/core/init";

// Actually initialising app
const elementId = "app";
initialiseApp(elementId);
