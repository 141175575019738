import { EuiBreadcrumbs, EuiSpacer } from "@elastic/eui";
import { List } from "immutable";
import PropTypes from "prop-types";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PanelActions from "app/common/components/PanelActions";
import PanelWarnings from "app/common/components/PanelWarnings";
import Tabs from "app/common/components/Tabs";
import { CustomIconWithLabel, Icons, emptyList } from "app/utils/constants";

// TODO
//  - replace Alert with @elasticui Callout
//  - replace Button, ButtonGroup and ButtonToolbar

const panelActionsStyle = {
  // backgroundColor: '#eaeaea',
  minWidth: "250px",
  marginLeft: "-20px",
  marginTop: "-20px",
  marginBottom: "-20px",
  marginRight: "20px",
  borderRight: "1px solid #eee",
  paddingTop: "50px",
  paddingBottom: "50px",
};

const panelFlexRowStyle = {
  display: "flex",
  flexDirection: "row",
};

const panelFlexColumnStyle = {
  display: "flex",
  flexDirection: "column",
  minWidth: "66%",
};

export const PanelBackButton = ({ onBack }) => (
  <Button onClick={onBack}>
    <CustomIconWithLabel icon={Icons.back} label={<FormattedMessage id="actions.Back" />} />
  </Button>
);

export const PanelSaveButton = ({ onSave }) => (
  <Button onClick={onSave} bsStyle="success">
    <CustomIconWithLabel icon={Icons.save} label={<FormattedMessage id="actions.Save" />} />
  </Button>
);

export const PanelEditButton = ({ onEdit }) => (
  <Button onClick={onEdit} bsStyle="primary">
    <CustomIconWithLabel icon={Icons.edit} label={<FormattedMessage id="actions.Edit" />} />
  </Button>
);

export const PanelDeleteButton = ({ onDelete }) => (
  <Button onClick={onDelete} bsStyle="danger">
    <CustomIconWithLabel icon={Icons.delete} label={<FormattedMessage id="actions.Delete" />} />
  </Button>
);

export const PanelActionButtons = ({ actionButtons, style }) => (
  <div className="panel-actions" style={style}>
    <ButtonToolbar>
      <ButtonGroup bsSize="small">{actionButtons}</ButtonGroup>
    </ButtonToolbar>
  </div>
);

const Panel = ({
  title,
  subtitle,
  header,
  footer,
  actionItems,
  breadcrumbs,
  tabs,
  warnings = emptyList,
  onSave,
  onEdit,
  onBack,
  onDelete,
  style,
  children,
}) => {
  const titlePart = title && (
    <div className="panel-title">
      <h4>{title}</h4>
      <i>{subtitle}</i>
    </div>
  );

  const warningsPart = !!warnings.size && <PanelWarnings warnings={warnings} />;

  const headerPart = header && <div className="panel-header">{header}</div>;

  const actionButtons = [
    onBack && <PanelBackButton key="back" onBack={onBack} />,
    onSave && <PanelSaveButton key="save" onSave={onSave} />,
    onEdit && <PanelEditButton key="edit" onEdit={onEdit} />,
    onDelete && <PanelDeleteButton key="delete" onDelete={onDelete} />,
  ].filter(Boolean);
  const actionButtonsPart = actionButtons.length > 0 && <PanelActionButtons actionButtons={actionButtons} />;

  const actionItemsPart = actionItems && (
    <div className="panel-actions" style={panelActionsStyle}>
      <PanelActions actionItems={actionItems} />
    </div>
  );

  const tabsPart = tabs && (
    <div className="panel-tabs">
      <Tabs tabs={tabs} />
    </div>
  );

  const footerPart = footer && <div className="panel-footer">{footer}</div>;

  const bodyPart = <div className="panel-body">{children}</div>;

  return (
    <>
      {breadcrumbs && (
        <>
          <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
          <EuiSpacer size="s" />
        </>
      )}

      <div className="panel panel-default" style={style}>
        <div style={panelFlexRowStyle}>
          {actionItemsPart}
          <div style={panelFlexColumnStyle}>
            {titlePart}
            {warningsPart}

            {headerPart}
            {tabsPart}
            {actionButtonsPart}
            {bodyPart}
            {footerPart}
          </div>
        </div>
      </div>
    </>
  );
};

Panel.propTypes = {
  tabs: PropTypes.array,
  actionItems: PropTypes.array,
  warnings: PropTypes.instanceOf(List),
};

export default Panel;
