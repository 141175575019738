import { EuiSwitch } from "@elastic/eui";
import { FormattedMessage } from "react-intl";
import BusyFeedback from "app/common/components/BusyFeedback";
import Panel from "app/common/components/Panel";
import useToggleState from "app/common/hooks/useToggleState";
import {
  getScopeRecurrenceUrl,
  useSnapshotScopeContext,
} from "app/features/snapshots/components/SnapshotScopeProvider";
import SnapshotScopeSimpleTable from "app/features/snapshots/components/SnapshotScopeSimpleTable";
import { newSnapshotScopeUrl } from "app/features/snapshots/routing";
import { useTenantNav, useTenantNavToUrl } from "app/features/tenants/hooks";

export const SnapshotScopeManager = ({ canEdit }) => {
  const {
    scopes,
    activeScopePk,

    handleActivateScope,
    handleDeleteScope,

    handleAddScopeRecurrence,
    handleDeleteScopeRecurrence,

    isLoadingScopes,
    isLoadingActiveScope,
  } = useSnapshotScopeContext();

  const { value: isCustomScopesOpen, toggle: toggleCustomScopesOpen } = useToggleState(activeScopePk !== null);

  const tenantNav = useTenantNav();
  const navToNewScope = useTenantNavToUrl(newSnapshotScopeUrl);
  const navToScopeRecurrence = ({ pk, recurrence }) => tenantNav(getScopeRecurrenceUrl(pk, recurrence?.pk));

  const scopesTable = (
    <SnapshotScopeSimpleTable
      scopes={scopes}
      activeScopePk={activeScopePk}
      onActivateScope={handleActivateScope}
      onAddScopeRecurrence={canEdit ? handleAddScopeRecurrence : null}
      onEditScopeRecurrence={canEdit ? navToScopeRecurrence : null}
      onDeleteScopeRecurrence={canEdit ? handleDeleteScopeRecurrence : null}
      onDeleteScope={canEdit ? handleDeleteScope : null}
      onNewScope={canEdit ? navToNewScope : null}
    />
  );

  const isLoading = isLoadingActiveScope || isLoadingScopes;
  const isNotTraditionalScope = !isCustomScopesOpen;
  const handleToggleTraditionalScope = (nextIsTraditionalScope) => {
    if (nextIsTraditionalScope && activeScopePk !== null) {
      handleActivateScope(null);
    }
    toggleCustomScopesOpen();
  };

  return (
    <>
      <EuiSwitch
        id={"oldScope"}
        name={"oldScope"}
        checked={isNotTraditionalScope}
        onChange={(e) => handleToggleTraditionalScope(e.target.value)}
        label={<FormattedMessage id="SnapshotScopeManager.useTraditionalScope" />}
        compressed
      />

      {isCustomScopesOpen && (
        <>
          <br />
          <br />
          {isLoading ? <BusyFeedback /> : scopesTable}
        </>
      )}
    </>
  );
};

const SnapshotScopeManagerPage = () => (
  <Panel title={<FormattedMessage id="SnapshotScopeManager.title" />}>
    <SnapshotScopeManager canEdit={true} />
  </Panel>
);

export default SnapshotScopeManagerPage;
