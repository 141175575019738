import { createSelector } from "reselect";
import { emptyMap, isMapLoading } from "app/utils/constants";

const getRootUsers = (state) => state.app.get("users");

export const getUser = createSelector([getRootUsers], (rootUsers) => rootUsers.get("user"));

export const getUserName = createSelector([getUser], (user) => user?.get("username"));

export const getUserIsYouPlanStaff = createSelector([getUser], (user) => user?.get("is_staff"));

export const getUserEmail = createSelector([getUser], (user) => user?.get("email"));

export const getUserProfilePkFromUser = createSelector([getUser], (user) => user?.get("user_profile"));

export const getUserIsLoaded = createSelector(
  [getUserName, getUserEmail],
  (userName, userEmail) => userName || userEmail,
);

export const getUserIsAllowedToDebug = createSelector([getRootUsers], (rootUsers) =>
  rootUsers.get("userIsAllowedToDebug"),
);

export const getUserIsInDebugMode = createSelector([getRootUsers], (rootUsers) => rootUsers.get("userIsInDebugMode"));

export const getUserNotAuthorised = createSelector([getRootUsers], (rootUsers) => rootUsers.get("userNotAuthorised"));

const getRootUserProfile = createSelector([getRootUsers], (rootUsers) => rootUsers.get("userProfile"));

export const getUserProfile = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("userProfile"),
);

export const getUserProfileIsLoading = createSelector([getRootUserProfile], (rootUserProfile) =>
  isMapLoading(rootUserProfile),
);

export const getUserTenant = createSelector([getUserProfile], (userProfile) =>
  userProfile ? userProfile.get("tenant") : emptyMap,
);

export const getUserTenantSchemaName = createSelector([getUserTenant], (tenant) => tenant?.get("schema_name"));
export const getUserTenantName = createSelector([getUserTenant], (tenant) => tenant?.get("name"));
export const getUserFirebaseUser = createSelector([getRootUsers], (r) => r.get("firebaseUser"));

export const getUserTenantId = createSelector([getUserTenant], (tenant) => tenant?.get("schema_name"));

export const getUserHasTenant = createSelector(
  [getUserTenantSchemaName],
  (tenantSchemaName) => tenantSchemaName && tenantSchemaName !== "public",
);

export const getUserHasPublicTenant = createSelector(
  [getUserTenantSchemaName],
  (tenantSchemaName) => tenantSchemaName === "public",
);

export const getUserProfileIsLoaded = createSelector(
  [getUserProfile, getUserProfileIsLoading],
  (userProfile, userProfileIsLoading) => !userProfileIsLoading && userProfile?.get("pk"),
);

export const getUserProfileLocale = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("locale"),
);

export const getUserRoleIsSpectator = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("isSpectator"),
);

export const getUserDisplayName = createSelector(
  [getRootUserProfile, getUserName],
  (rootUserProfile, userName) => rootUserProfile.get("displayName") || userName,
);

export const getUserProfilePk = createSelector([getRootUserProfile], (rootUserProfile) => rootUserProfile.get("pk"));

export const getUserProfileIntervalType = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardIntervalType"),
);

export const getUserProfileWorkHoursAllDay = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardAllDay"),
);

export const getUserProfileWorkHoursStartTime = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardStartTime"),
);

export const getUserProfileWorkHoursEndTime = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardEndTime"),
);

export const getUserProfileColorAvailable = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardColorAvailable"),
);

export const getUserProfileColorUnavailable = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardColorUnavailable"),
);

export const getUserProfileColorRatherNot = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardColorRatherNot"),
);

export const getUserProfileProjectsDefaultCollapsed = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardProjectsDefaultCollapsed"),
);

export const getUserProfilePlanningExportLabelsResources = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planningExportLabelsResources"),
);

export const getUserProfilePlanningExportLabelsAssignments = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planningExportLabelsAssignments"),
);

export const getUserProfilePlanningAutoLoadChanges = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planningAutoLoadChanges"),
);

export const getUserProfileGroupAssignments = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardGroupAssignments"),
);

export const getUserProfileDisplayTextAssignmentsResources = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardDisplayTextAssignmentsResources"),
);

export const getUserProfileDisplayTextAssignmentsWorks = createSelector([getRootUserProfile], (rootUserProfile) =>
  rootUserProfile.get("planBoardDisplayTextAssignmentsWorks"),
);

export default {};
