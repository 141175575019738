import { Property } from "csstype";
import { CSSProperties, ReactNode } from "react";

interface BusyFeedbackProps {
  hide?: boolean;
  label?: ReactNode | string | null;
  style?: CSSProperties;
}

const busyFeedbackStyle = {
  height: "100%",
  padding: "2em",
  textAlign: "center" as Property.TextAlign,
  color: "#58666E",
};

export const Spinner = () => <i className="fa fa-spinner fa-spin" />;

const BusyFeedback = ({ label = null, hide = false, style = {} }: BusyFeedbackProps) => {
  if (hide) return null;

  return (
    <div className="BusyFeedback" style={{ ...busyFeedbackStyle, ...style }}>
      <Spinner />
      {label ? <>&nbsp;{label}</> : null}
    </div>
  );
};

export default BusyFeedback;
