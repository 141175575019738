import { useEffect, useRef } from "react";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import BusyFeedbackPage from "app/common/components/BusyFeedbackPage";
import { useAppActions } from "app/actions";
import { useFirebaseAuthContext } from "app/features/auth/components/FirebaseAuthProvider";
import { useNavToUrl } from "app/features/tenants/hooks";

const useLogout = () => {
  const actions = useAppActions();
  const { clearDetectedTenant } = useAppTenantContext() as { clearDetectedTenant: () => void };
  const { signOutFirebaseUser } = useFirebaseAuthContext() as { signOutFirebaseUser: () => Promise<void> };
  const navToIndex = useNavToUrl("/");

  const isLoggingOutRef = useRef(false);

  // TODO
  //  - Instead of immediately navigating to index we could present a successfully logged out page, that disappears
  //  after a while with:
  //   * link to index
  //   * a message that if a user had a firebase user before navigating to links in youplan might automatically sign
  //   them in again because they will not be signed out from the SSO-provider
  //  - If logging out fails, show a message that it failed and we will try again in a few seconds.

  useEffect(() => {
    if (isLoggingOutRef.current) return;

    isLoggingOutRef.current = true;

    signOutFirebaseUser().then(() => {
      clearDetectedTenant();
      // TODO handle failure, retry after a few seconds
      actions.users.logOut().payload.then(navToIndex);
    });
  }, [actions, clearDetectedTenant, signOutFirebaseUser, navToIndex]);
};

const Logout = () => {
  useLogout();
  return <BusyFeedbackPage />;
};

export default Logout;
