import { List } from "immutable";

interface FormErrorsProps {
  errors: List<string>;
}

const FormErrors = ({ errors }: FormErrorsProps) =>
  errors &&
  errors.map((error) => (
    <div key={error} className="alert alert-danger">
      {error}
    </div>
  ));

export default FormErrors;
