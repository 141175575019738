// TODO use CustomIconWithLabel

interface HelpBlockProps {
  text?: string;
}

const HelpIcon = () => <i className="fa fa-info-circle" />;

const HelpBlock = ({ text }: HelpBlockProps) => {
  if (!text || text.length === 0) return null;
  return (
    <span className="help-block">
      <HelpIcon />
      &nbsp;
      {text}
    </span>
  );
};

export default HelpBlock;
