import { useMemo } from "react";
import { useDebugMode } from "app/core/hooks";
import JSONDisplay, { objWithKeysSorted } from "app/common/details/JSONDisplay";
import useToggleState from "app/common/hooks/useToggleState";

const formDebugToggleStyle = { fontSize: 10 };

const FormDebug = ({ formState }) => {
  const debugMode = useDebugMode();

  // TODO lift this state up (only show when true in some kind of debugSettings)
  const { value: showFormDebug, toggle: toggleFormDebug } = useToggleState();

  const debugFormState = useMemo(() => {
    const debugFormState = { ...formState };

    const { cleanedData, values, valuesInitial } = formState;

    if (cleanedData) debugFormState.cleanedData = objWithKeysSorted(cleanedData);
    if (values) debugFormState.values = objWithKeysSorted(values.toObject());
    if (valuesInitial) debugFormState.valuesInitial = objWithKeysSorted(valuesInitial.toObject());

    return debugFormState;
  }, [formState]);
  if (!debugMode) return null;

  const debugToggle = (
    <div className="pull-right">
      <a style={formDebugToggleStyle} onClick={toggleFormDebug}>
        {`${showFormDebug ? "Hide" : "Show"} form debug`}
      </a>
    </div>
  );

  return (
    <>
      {debugToggle}
      {showFormDebug && (
        <>
          <br />
          <hr />
          <b>Form state</b>
          <JSONDisplay data={debugFormState} />
          <hr />
        </>
      )}
    </>
  );
};

export default FormDebug;
