import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useLocalStorageState from "app/common/hooks/useLocalStorageState";
import useToggleState from "app/common/hooks/useToggleState";
import InternalDal from "app/utils/InternalDal";
import { emptySet } from "app/utils/constants";
import { getUserIsLoaded, getUserTenantSchemaName } from "app/features/users/selectors";

const AppTenantDetectionContext = createContext({});

// These are the routes that shouldn't belong to a tenant,
// these can be used to pre-parse routes.
const routesNoTenant = ["about", "api", "admin", "debug", "login", "logout", "user", "tenants"];
const routesNoTenantSet = emptySet.concat(routesNoTenant);

const getEmptyTenantIdp = () => ({
  gcipId: null,
  tenantSchemaName: null,
});

const useSchemaNameFromRoute = () => {
  const { pathname } = useLocation();
  const schemaName = pathname.split("/")[1];
  return routesNoTenantSet.contains(schemaName) ? null : schemaName;
};

const AppTenantDetectionProvider = ({ children }) => {
  // TODO
  //  - clean up the stored state we should have something like TenantAuthDetails class
  //  - the api call to auth/flow/init is triggered twice when switching tenants, this should not be necessary...
  const userLoaded = useSelector(getUserIsLoaded);
  const userTenantSchemaName = useSelector(getUserTenantSchemaName);
  // const userIsLoggingOut = useSelector(getUserIsLoggingOut);

  const schemaNameFromRoute = useSchemaNameFromRoute();
  const [schemaNameFromLocalStorage, setSchemaNameInLocalStorage] = useLocalStorageState("tenantSchemaName", null);

  const [tenantIdentityProvider, setTenantIdentityProvider] = useState(getEmptyTenantIdp);
  const [isTenantDetected, setIsTenantDetected] = useState(false);
  const [isUnknownTenant, setIsUnknownTenant] = useState(false);

  const stopLoadingTimeoutRef = useRef(-1);

  const { gcipId: tenantIdPGcipId, tenantSchemaName: tenantIdPSchemaName } = tenantIdentityProvider;

  const {
    value: isLoadingTenantDetails,
    on: startLoadingTenantDetails,
    off: stopLoadingTenantDetails,
  } = useToggleState();

  const detectedSchemaName = schemaNameFromRoute || schemaNameFromLocalStorage;
  const isTenantInitialized = isTenantDetected && tenantIdPSchemaName === detectedSchemaName;
  const isTenantWithFirebase = isTenantInitialized && tenantIdPGcipId !== null;
  const isTenantLoaded = isTenantInitialized && tenantIdPSchemaName === userTenantSchemaName;
  const isWaitingForTenant =
    !isUnknownTenant && !isTenantInitialized && !(isTenantDetected && detectedSchemaName === null);

  const shouldStoreDetectedSchemaName =
    userLoaded &&
    // && !userIsLoggingOut TODO buggy when switching
    detectedSchemaName !== schemaNameFromLocalStorage;

  useEffect(() => {
    setIsTenantDetected(true);
  }, []);
  useEffect(() => {
    if (shouldStoreDetectedSchemaName) {
      setSchemaNameInLocalStorage(detectedSchemaName);
    }
  }, [detectedSchemaName, shouldStoreDetectedSchemaName, setSchemaNameInLocalStorage]);

  const clearDetectedTenant = useCallback(() => {
    setSchemaNameInLocalStorage(null);
  }, [setSchemaNameInLocalStorage]);
  const getTenantAuthDetails = useCallback(
    (tenantSchemaName) => {
      startLoadingTenantDetails();

      const dal = new InternalDal();
      dal.authHeader = null;
      return dal
        .poster(`${dal.basePublicUrl}/auth/init`, { schema_name: tenantSchemaName }, "initAuthFlow")
        .then((response) => {
          const data = response.body;
          const {
            tenant_name: tenantName,
            gcip_id: gcipId,
            gcip_oidc_provider_id: oidcProviderId,
            gcip_oidc_client_id: oidcClientId,
            gcip_oidc_client_secret: oidcClientSecret,
            gcip_oidc_issuer: oidcIssuer,
            gcip_saml_provider_id: samlProviderId,
          } = data;

          // TODO
          //  If any of the values needed isn't configured we should mark the tenant as improperly configured
          const tenantIdentityProvider = {
            tenantName,
            tenantSchemaName,
            gcipId,
          };
          if (gcipId) {
            if (samlProviderId) {
              tenantIdentityProvider.samlProviderId = samlProviderId;
            } else if (oidcProviderId) {
              tenantIdentityProvider.oidcProviderId = oidcProviderId;
              tenantIdentityProvider.oidcClientId = oidcClientId;
              tenantIdentityProvider.oidcClientSecret = oidcClientSecret;
              tenantIdentityProvider.oidcIssuer = oidcIssuer;
            }
          }

          setTenantIdentityProvider(tenantIdentityProvider);
          stopLoadingTenantDetails();

          // Suppress warnings
          return null;
        })
        .catch(() => {
          // TODO
          //  - just show regular Login?
          //  - if connection error occurred we could retry a bit later?
          //  - what if throttled?
          //  - set a max number of tries, show connection problems in app
          setIsUnknownTenant(true);
          // Ugly solution for now, but makes sure the next load isn't triggered immediately!
          stopLoadingTimeoutRef.current = setTimeout(() => {
            stopLoadingTenantDetails();
          }, 2000);
        });
    },
    [startLoadingTenantDetails, stopLoadingTenantDetails],
  );

  useEffect(() => {
    return () => {
      clearTimeout(stopLoadingTimeoutRef.current);
    };
  });
  useEffect(() => {
    // Effect to grab tenant name and gcipId from API.
    if (!isTenantDetected) return;
    if (!detectedSchemaName) return;
    if (isLoadingTenantDetails) return;
    if (detectedSchemaName === tenantIdPSchemaName) return;

    getTenantAuthDetails(detectedSchemaName);
  }, [detectedSchemaName, getTenantAuthDetails, isLoadingTenantDetails, isTenantDetected, tenantIdPSchemaName]);

  const contextValue = {
    // Loaded
    tenantIdentityProvider,
    // Detected
    detectedSchemaName,
    isTenantDetected,
    isTenantInitialized,
    isTenantLoaded,
    isTenantWithFirebase,
    isUnknownTenant,
    isWaitingForTenant,
    clearDetectedTenant,
  };

  return <AppTenantDetectionContext.Provider value={contextValue}>{children}</AppTenantDetectionContext.Provider>;
};

export const useAppTenantContext = () => useContext(AppTenantDetectionContext);

export default AppTenantDetectionProvider;
