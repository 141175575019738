import { fromJS } from "immutable";
import { emptyMap, isLoadingMap, notLoadedMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
  travelTimes: {},
  travelTimesMissing: {},
});

function handleLoadSimpleLocations(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("all", isLoadingMap);
  }
  if (action.status === "done") {
    const locationsJS = action.payload.body.results;
    const locationsMap = arrayToPkMap(locationsJS, "location");
    state = state.set("all", locationsMap);
  }
  return state;
}

function handleClearSimpleLocations(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("all", notLoadedMap);
  }
  return state;
}

function handleLoadDetailedLocation(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const locationJS = action.payload.body;
    const locationMap = fromJS(locationJS);
    state = state.set("detailed", locationMap);
  }
  return state;
}

function handleClearDetailedLocation(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("detailed", notLoadedMap);
  }
  return state;
}

function handleClearTravelTimes(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("travelTimes", emptyMap);
  }
  return state;
}

function handleLoadTravelTimes(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("travelTimes", isLoadingMap);
  }
  if (action.status === "done") {
    const travelTimesJS = action.payload.body.results;
    const travelTimesMap = fromJS(travelTimesJS).map((tt) => tt.set("type", "travelTime"));
    state = state.set("travelTimes", travelTimesMap);
  }
  return state;
}

function handleLoadMissingTravelTimes(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("travelTimesMissing", isLoadingMap);
  }
  if (action.status === "done") {
    const travelTimesJS = action.payload.body;
    const travelTimesMap = fromJS(travelTimesJS).map((tt) => tt.set("type", "travelTime"));
    state = state.set("travelTimesMissing", travelTimesMap);
  }
  return state;
}

function handleClearMissingTravelTimes(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("travelTimesMissing", emptyMap);
  }
  return state;
}

//
// Reducer
//

function locationsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_LOCATIONS]: handleLoadSimpleLocations,
    [actionTypes.LOAD_DETAILED_LOCATION]: handleLoadDetailedLocation,
    [actionTypes.CLEAR_SIMPLE_LOCATIONS]: handleClearSimpleLocations,
    [actionTypes.CLEAR_DETAILED_LOCATION]: handleClearDetailedLocation,

    [actionTypes.LOAD_MISSING_TRAVEL_TIMES]: handleLoadMissingTravelTimes,
    [actionTypes.LOAD_TRAVEL_TIMES]: handleLoadTravelTimes,
    [actionTypes.CLEAR_MISSING_TRAVEL_TIMES]: handleClearMissingTravelTimes,
    [actionTypes.CLEAR_TRAVEL_TIMES]: handleClearTravelTimes,
  });
}

export default locationsReducer;
