import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid4 } from "uuid";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import { useAppTenantUserLinkContext } from "app/core/providers/AppTenantUserLinkProvider";
import useToggleState from "app/common/hooks/useToggleState";
import moment from "app/utils/momentLocalized";
import { getUserTenantId } from "app/features/users/selectors";

const socketStates = {
  OPEN: "open",
  CLOSED: "closed",
  CONNECTING: "connecting",
  ERROR: "error",
};

const defaultMsgType = "chat.message";
const defaultReconnectWaitInMs = 100;
const nrOfMessagesToKeep = 25;

// TODO don't we have this in utils?
const isJSON = (s) => {
  try {
    return JSON.parse(s) && !!s;
  } catch (_) {
    return false;
  }
};

export const useTenantAwareWebSocket = () => {
  const tenantId = useSelector(getUserTenantId);
  const tenantIdRef = useRef(null);

  const { isTenantUserSpectator } = useAppTenantUserLinkContext();
  const { isTenantLoaded } = useAppTenantContext();

  const [messagesReceived, setMessagesReceived] = useState([]);
  const [messagesSent, setMessagesSent] = useState([]);
  const { value: isDisabled, on: disableWs, off: enableWs } = useToggleState(isTenantUserSpectator);
  const isEnabled = !isDisabled;

  const countReceivedRef = useRef(0);
  const countSentRef = useRef(0);
  const reconnectWaitInMsRef = useRef(defaultReconnectWaitInMs);
  const reconnectIdRef = useRef(null);
  const [lastConnected, setLastConnected] = useState(null);

  const wsRef = useRef(null);
  const [wsState, setWsState] = useState(socketStates.CLOSED);
  const isConnected = wsState === socketStates.OPEN;

  const nrMessagesReceived = messagesReceived.length;
  const nrMessagesSent = messagesSent.length;

  const lastMessageReceived = nrMessagesReceived > 0 ? messagesReceived[nrMessagesReceived - 1] : null;
  const lastMessageSent = nrMessagesSent > 0 ? messagesSent[nrMessagesSent - 1] : null;

  const notProperlyConnected =
    tenantIdRef.current !== tenantId || wsState === socketStates.CLOSED || wsState === socketStates.ERROR;
  const isReadyToConnect = isEnabled && tenantId && isTenantLoaded;
  const shouldReconnect = isReadyToConnect && notProperlyConnected;

  const sendMessage = useCallback((msg, msgType = defaultMsgType) => {
    // TODO
    //  - catch errors, what if state is wrong resend when open?
    //  - construct a simple message queue that is flushed when connection state opens again

    countSentRef.current += 1;
    const message = {
      msg: isJSON(msg) ? JSON.parse(msg) : msg,
      msg_type: msgType,
      id: countSentRef.current,
      uuid: uuid4(),
    };
    const messageStr = JSON.stringify(message);

    const ws = wsRef.current;
    ws.send(messageStr);

    setMessagesSent((s) => [...s, message].slice(-nrOfMessagesToKeep));
  }, []);

  const clearMessages = useCallback(() => {
    setMessagesReceived([]);
    setMessagesSent([]);
  }, []);

  const disconnectFromWs = useCallback(() => {
    clearTimeout(reconnectIdRef.current);
    wsRef.current?.close();
  }, []);

  const connectToWs = useCallback(() => {
    // TODO
    //  - deal with state/messages initialization
    // Close previous connection
    disconnectFromWs();
    // Stop early if not ready to connect
    if (!isReadyToConnect) return;

    setWsState(socketStates.CONNECTING);

    const { host, protocol } = window.location;
    const wssOrWs = protocol === "https:" ? "wss" : "ws";
    const socketPath = `${wssOrWs}://${host}/api/ws/${tenantId}/`;

    const ws = new WebSocket(socketPath);
    wsRef.current = ws;
    tenantIdRef.current = tenantId;

    ws.onmessage = (e) => {
      countReceivedRef.current += 1;

      const data = JSON.parse(e.data);
      data.id = countReceivedRef.current;
      data.uuid = uuid4();
      data.now = moment().local().format("YYYY-MM-DD HH:mm:ss");

      setMessagesReceived((s) => [...s, data].slice(-nrOfMessagesToKeep));
    };

    ws.onopen = () => {
      setWsState(socketStates.OPEN);
      setLastConnected(moment());

      // Cleanup refs
      clearTimeout(reconnectIdRef.current);
      reconnectWaitInMsRef.current = defaultReconnectWaitInMs;

      console.log(`[YouPlan] socket for ${tenantId} opened`);
    };
    ws.onerror = () => {
      setWsState(socketStates.ERROR);
      reconnectWaitInMsRef.current = Math.min(2 * reconnectWaitInMsRef.current, 5000);

      clearTimeout(reconnectIdRef.current);
      reconnectIdRef.current = setTimeout(connectToWs, reconnectWaitInMsRef.current);
    };
    ws.onclose = () => {
      // TODO this part is always reached through onerror?
      setWsState(socketStates.CLOSED);
      console.log(`[YouPlan] socket for ${tenantId} closed`);
    };
  }, [disconnectFromWs, tenantId, isReadyToConnect]);

  // Auto-enable/disable effect when user isTenantUserSpectator or not
  useEffect(() => {
    if (isTenantUserSpectator) {
      disableWs();
    } else {
      enableWs();
    }
  }, [isTenantUserSpectator, enableWs, disableWs]);

  // Auto-reconnect effect
  useEffect(() => {
    if (shouldReconnect) {
      disconnectFromWs();
      reconnectIdRef.current = setTimeout(connectToWs, reconnectWaitInMsRef.current);
    }
  }, [connectToWs, disconnectFromWs, shouldReconnect]);

  // Disconnect when isDisabled
  useEffect(() => {
    if (isDisabled) {
      disconnectFromWs();
    }
  }, [disconnectFromWs, isDisabled]);

  // Disconnect cleanup
  useEffect(() => {
    return () => {
      disconnectFromWs();
    };
  }, [disconnectFromWs]);

  return {
    lastMessageReceived,
    lastMessageSent,
    lastConnected,

    messagesReceived,
    messagesSent,

    clearMessages,
    sendMessage,

    wsState,
    connectToWs,
    isConnected,

    enableWs,
    disableWs,
    isDisabled,
  };
};
