import { List } from "immutable";

interface ErrorListProps {
  errors: List<string>;
}

const ErrorList = ({ errors }: ErrorListProps) => {
  if (!errors || !errors.count()) return null;

  return (
    <div className="error-list">
      {errors
        .map((e) => (
          <span key={e} className="help-block">
            {e}
          </span>
        ))
        .toArray()}
    </div>
  );
};

export default ErrorList;
