import log from "loglevel";
import { State } from "app/utils/redux/reducerHandlers";
import { Action } from "app/utils/redux/standardActions";

function reduceWithHandlers(
  state: State,
  action: Action,
  handlers: { [key: string]: (state: State, action: Action) => unknown },
): unknown {
  const { type } = action;

  if (!type) {
    log.warn("Action does not have type.", action);
  }

  const handler = handlers[type];
  const newState = handler ? handler(state, action) : state;
  return newState;
}

export default reduceWithHandlers;
