import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

interface FirebaseAuthFlowFooterProps {
  tenantName: string;
  redirectUrl: string;
}

// TODO there must be a better way to construct a component like this
const FirebaseAuthFlowFooter = ({ tenantName, redirectUrl }: FirebaseAuthFlowFooterProps) => (
  <div>
    <FormattedMessage id="FirebaseAuthFlowFooter.tenantSpecificLogin" />
    &nbsp;<b>{tenantName}</b>.<br />
    <FormattedMessage id="FirebaseAuthFlowFooter.regularLogin" />
    &nbsp;
    <Link to={`/login?redirectUrl=${redirectUrl}`}>
      <FormattedMessage id="FirebaseAuthFlowFooter.usernameAndPassword" />
    </Link>
    .
  </div>
);

export default FirebaseAuthFlowFooter;
