import { Map, fromJS } from "immutable";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import { Action } from "app/utils/redux/standardActions";

interface ActionWithSimplePayload extends Action {
  payload: {
    body: {
      results: unknown[];
    };
  };
}

interface ActionWithDetailedPayload extends Action {
  payload: {
    body: unknown;
  };
}
export type State = Map<string, unknown>;

const handleLoadDetailed = (state: State, action: ActionWithDetailedPayload, keyArr: string[]) => {
  if (action.status === "pending") {
    return state.setIn(keyArr, isLoadingMap);
  } else if (action.status === "done") {
    return state.setIn(keyArr, fromJS(action.payload.body));
  } else {
    return state;
  }
};

const handleClearDetailed = (state: State, action: Action, keyArr: string[]) =>
  action.status === "done" ? state.setIn(keyArr, notLoadedMap) : state;

const handleLoadSimple = (
  state: State,
  action: ActionWithSimplePayload,
  keyArr: string[],
  itemType: string | null = null,
) => {
  if (action.status === "pending") {
    return state.setIn(keyArr, isLoadingMap);
  } else if (action.status === "done") {
    const dataJS = action.payload.body.results;
    // @ts-expect-error TODO fix this
    const dataMap = arrayToPkMap(dataJS, itemType);
    return state.setIn(keyArr, dataMap);
  } else {
    return state;
  }
};

export const keyArrToLoadSimpleHandler =
  (keyArr: string[], itemType: string | null = null) =>
  (state: State, action: Action) =>
    handleLoadSimple(state, action as ActionWithSimplePayload, keyArr, itemType);
export const keyArrToLoadDetailedHandler = (keyArr: string[]) => (state: State, action: Action) =>
  handleLoadDetailed(state, action as ActionWithDetailedPayload, keyArr);
export const keyArrToClearHandler = (keyArr: string[]) => (state: State, action: Action) =>
  handleClearDetailed(state, action, keyArr);
