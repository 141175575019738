import classNames from "classnames";
import { fromJS } from "immutable";
import { Component } from "react";
import { toggleItem } from "app/utils/immutableUtils";

class MultiButtonSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: fromJS(props.value),
    };
  }

  handleChange = () => {
    this.props.onChange(this.state.selectedValues.toJS());
  };

  handleClick = (clickedValue) => {
    this.setState((s) => ({ selectedValues: toggleItem(s.selectedValues, clickedValue) }), this.handleChange);
  };

  renderOption = ({ value, label }) => (
    <a
      key={value}
      className={classNames("btn", "btn-default", { "btn-success": this.state.selectedValues.includes(value) })}
      onClick={() => {
        this.handleClick(value);
      }}
    >
      {label}
    </a>
  );

  render() {
    const { options } = this.props;
    return (
      <div className="btn-group" data-toggle="buttons">
        {options.map(this.renderOption)}
      </div>
    );
  }
}

export default MultiButtonSelect;
