import { fromJS } from "immutable";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import { ColorSlotRecord } from "app/utils/records";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "app/features/calendars/actions";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
  preview: {
    colorSlots: [],
  },
});

//
// Handler for LOAD_SIMPLE_CALENDARS_ASYNC action
//
function handleLoadSimpleCalendarsAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("all", isLoadingMap);
  }
  if (action.status === "done") {
    const calendarsJS = action.payload.body.results;
    const calendarsMap = fromJS(calendarsJS);
    state = state.set("all", calendarsMap);
  }
  return state;
}

function handleClearSimpleCalendars(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("all", notLoadedMap);
  }
  return state;
}

//
// Handler for LOAD_DETAILED_CALENDAR_ASYNC action
//
function handleLoadDetailedCalendarAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const calendarJS = action.payload.body;
    const calendarMap = fromJS(calendarJS);
    state = state.set("detailed", calendarMap);
  }
  return state;
}

//
// Handler for CLEAR_DETAILED_CALENDAR action
//
function handleClearDetailedCalendar(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("detailed", notLoadedMap);
  }
  return state;
}

//
// Handler for LOAD_CALENDAR_PREVIEW_ASYNC action
//
function handleLoadCalendarPreviewAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.setIn(["preview", "colorSlots"], isLoadingMap);
  }
  if (action.status === "done") {
    const colorSlotsJS = action.payload.body.results;
    const colorSlots = fromJS(colorSlotsJS).map(ColorSlotRecord);
    state = state.setIn(["preview", "colorSlots"], colorSlots);
  }

  return state;
}

function calendarsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_CALENDARS_ASYNC]: handleLoadSimpleCalendarsAsync,
    [actionTypes.LOAD_DETAILED_CALENDAR_ASYNC]: handleLoadDetailedCalendarAsync,
    [actionTypes.CLEAR_SIMPLE_CALENDARS]: handleClearSimpleCalendars,
    [actionTypes.CLEAR_DETAILED_CALENDAR]: handleClearDetailedCalendar,
    [actionTypes.LOAD_CALENDAR_PREVIEW_ASYNC]: handleLoadCalendarPreviewAsync,
  });
}

export default calendarsReducer;
