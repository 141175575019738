/***
 * Returns a moment-compatible time interval name corresponding to an app time interval.
 * Useful for moment operations based on the current app time interval
 *   e.g. moment(startDate).add(1, timeIntervalForMoment('workweek'))
 * @param planBoardIntervalType
 * @returns {*} Interval type name to use with moment.
 */

// TODO add other interval types as needed ("month", "year", "custom", etc.)
type IntervalType = "isoweek" | "week" | "workweek" | string;

const mapping: Record<string, string> = {
  // Depending on locale, startOf('week') could be a Sunday or a Monday.
  // Let's keep it ISO, always Monday.
  isoweek: "isoweek",
  week: "isoweek",
  workweek: "isoweek",
};

const intervalTypeForMoment = (intervalType: IntervalType): string => mapping[intervalType] || intervalType;

export default intervalTypeForMoment;
