import InternalDal from "app/utils/InternalDal";

class WorkDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);

    const u = this.dal.baseTenantUrl;
    this.projectsUrl = `${u}/projects`;
    this.projectsDetailedUrl = `${u}/projects/detailed`;

    this.worksUrl = `${u}/works`;
    this.worksDetailedUrl = `${u}/works/detailed`;

    this.assignmentsUrl = `${u}/assignments`;
    this.assignmentTemplatesUrl = `${u}/assignments/templates`;
    this.assignmentsDetailedUrl = `${u}/assignments/detailed`;

    this.importancesUrl = `${u}/work-importances`;
    this.precedencesUrl = `${u}/work-precedences`;

    this.worksSameResourcesUrl = `${u}/works-same-resources`;
    this.worksResourcesClaimsUrl = `${u}/works-resources-claims`;
    this.worksSpansUrl = `${u}/works-spans`;
    this.worksConditionalsUrl = `${u}/works-conditionals`;
  }

  cloneProject(projectPk) {
    const url = `${this.projectsUrl}/clone-project/`;

    return this.dal.poster(url, { project: projectPk }, "cloneProject");
  }

  createProject(project) {
    const url = `${this.projectsUrl}/`;

    return this.dal.poster(url, project, "createProject");
  }

  createTemplateFromProject(projectPk) {
    const url = `${this.dal.baseTenantUrl}/create-template-from-project/`;
    const data = { project: projectPk };

    return this.dal.poster(url, data, "createTemplateFromProject");
  }

  putProject(project) {
    const url = `${this.projectsUrl}/${project.pk}/`;

    return this.dal.putter(url, project, "putProject");
  }

  deleteProject(projectPk) {
    const url = `${this.projectsUrl}/${projectPk}/`;

    return this.dal.deleter(url, "deleteProject");
  }

  bulkActionProjects(projectPks, bulkType) {
    const url = `${this.dal.baseTenantUrl}/bulk-action-projects/`;
    const data = {
      projects: projectPks,
      bulk_type: bulkType,
    };

    return this.dal.poster(url, data, `bulkActionProjects: ${bulkType}`);
  }

  getDetailedProject(projectPk) {
    const url = `${this.projectsDetailedUrl}/${projectPk}/`;

    return this.dal.getter(url, "getDetailedProject");
  }

  getDetailedWork(workPk) {
    const url = `${this.worksDetailedUrl}/${workPk}/`;

    return this.dal.getter(url, "getDetailedWork");
  }

  getDetailedPrecedence(precedencePk) {
    const url = `${this.precedencesUrl}/${precedencePk}/`;

    return this.dal.getter(url, "getDetailedPrecedence");
  }

  createPrecedence(precedence) {
    const url = `${this.precedencesUrl}/`;

    return this.dal.poster(url, precedence, "createPrecedence");
  }

  putPrecedence(precedence) {
    const url = `${this.precedencesUrl}/${precedence.pk}/`;

    return this.dal.putter(url, precedence, "putPrecedence");
  }

  deletePrecedence(precedencePk) {
    const url = `${this.precedencesUrl}/${precedencePk}/`;

    return this.dal.deleter(url, "deletePrecedence");
  }

  cloneWork(workPk) {
    const url = `${this.worksUrl}/clone-work/`;

    return this.dal.poster(url, { work: workPk }, "cloneWork");
  }

  createWork(work) {
    const url = `${this.worksDetailedUrl}/`;

    return this.dal.poster(url, work, "createWork");
  }

  createWorkFromTemplate(work, workTemplatePk) {
    const url = `${this.dal.baseTenantUrl}/create-work-from-template/`;
    const data = Object.assign({}, work, { work_template: workTemplatePk });

    return this.dal.poster(url, data, "createWorkFromTemplate");
  }

  createTemplateFromWork(workPk) {
    const url = `${this.dal.baseTenantUrl}/create-template-from-work/`;
    const data = { work: workPk };

    return this.dal.poster(url, data, "createTemplateFromWork");
  }

  putWork(work) {
    const url = `${this.worksDetailedUrl}/${work.pk}/`;

    return this.dal.putter(url, work, "putWork");
  }

  patchWork(work) {
    const url = `${this.worksDetailedUrl}/${work.pk}/`;

    return this.dal.patcher(url, work, "patchWork");
  }

  deleteWork(workPk) {
    const url = `${this.worksUrl}/${workPk}/`;

    return this.dal.deleter(url, "deleteWork");
  }

  bulkActionWorks(workPks, bulkType) {
    const url = `${this.dal.baseTenantUrl}/bulk-action-works/`;
    const data = {
      works: workPks,
      bulk_type: bulkType,
    };

    return this.dal.poster(url, data, `bulkActionWorks: ${bulkType}`);
  }

  //
  // ASSIGNMENTS
  //
  getDetailedAssignment(assignmentPk) {
    const url = `${this.assignmentsDetailedUrl}/${assignmentPk}/`;

    return this.dal.getter(url, "getDetailedAssignment");
  }

  createAssignment(assignment) {
    const url = `${this.assignmentsUrl}/`;

    return this.dal.poster(url, assignment, "createAssignment");
  }

  deleteAssignment(assignmentPk) {
    const url = `${this.assignmentsUrl}/${assignmentPk}/`;

    return this.dal.deleter(url, "deleteAssignment");
  }

  bulkActionAssignments(assignmentPks, bulkType) {
    const url = `${this.dal.baseTenantUrl}/bulk-action-assignments/`;
    const data = {
      assignments: assignmentPks,
      bulk_type: bulkType,
    };

    return this.dal.poster(url, data, `bulkActionAssignments: ${bulkType}`);
  }

  updateAssignment(assignment) {
    const url = `${this.assignmentsUrl}/${assignment.pk}/`;

    return this.dal.putter(url, assignment, "updateAssignment");
  }

  patchAssignment(assignment) {
    const url = `${this.assignmentsUrl}/${assignment.pk}/`;

    return this.dal.patcher(url, assignment, "patchAssignment");
  }

  //
  // ASSIGNMENT-TEMPLATES
  //
  getDetailedAssignmentTemplate(assignmentTemplatePk) {
    const url = `${this.assignmentTemplatesUrl}/${assignmentTemplatePk}/`;

    return this.dal.getter(url, "getDetailedAssignmentTemplate");
  }

  createAssignmentTemplate(assignmentTemplate) {
    const url = `${this.assignmentTemplatesUrl}/`;

    return this.dal.poster(url, assignmentTemplate, "createAssignmentTemplate");
  }

  patchAssignmentTemplate(assignmentTemplate) {
    const url = `${this.assignmentTemplatesUrl}/${assignmentTemplate.pk}`;

    return this.dal.patcher(url, assignmentTemplate, "patchAssignmentTemplate");
  }

  updateAssignmentTemplate(assignmentTemplate) {
    const url = `${this.assignmentTemplatesUrl}/${assignmentTemplate.pk}/`;

    return this.dal.putter(url, assignmentTemplate, "updateAssignmentTemplate");
  }

  deleteAssignmentTemplate(assignmentTemplatePk) {
    const url = `${this.assignmentTemplatesUrl}/${assignmentTemplatePk}/`;

    return this.dal.deleter(url, "deleteAssignmentTemplate");
  }

  cloneAssignmentTemplate(assignmentTemplatePk) {
    const url = `${this.assignmentTemplatesUrl}/clone-assignment-template/`;

    return this.dal.poster(url, { assignment_template: assignmentTemplatePk }, "cloneAssignmentTemplate");
  }

  bulkActionAssignmentTemplates(assignmentTemplatePks, bulkType) {
    const url = `${this.dal.baseTenantUrl}/bulk-action-assignment-templates/`;
    const data = {
      pks: assignmentTemplatePks,
      bulk_type: bulkType,
    };

    return this.dal.poster(url, data, `bulkActionAssignmentTemplates: ${bulkType}`);
  }

  //
  // WORK IMPORTANCES
  //
  getWorkImportances() {
    const url = `${this.importancesUrl}/`;

    return this.dal.getter(url, "getWorkImportances");
  }

  getDetailedWorkImportance(workImportancePk) {
    const url = `${this.importancesUrl}/${workImportancePk}/`;

    return this.dal.getter(url, "getDetailedWorkImportance");
  }

  createWorkImportance(workImportance) {
    const url = `${this.importancesUrl}/`;

    return this.dal.poster(url, workImportance, "createWorkImportance");
  }

  putWorkImportance(workImportance) {
    const url = `${this.importancesUrl}/${workImportance.pk}/`;

    return this.dal.putter(url, workImportance, "putWorkImportance");
  }

  deleteWorkImportance(workImportancePk) {
    const url = `${this.importancesUrl}/${workImportancePk}/`;

    return this.dal.deleter(url, "deleteWorkImportance");
  }

  //
  // WORKS SAME RESOURCES
  //
  getDetailedWorksSameResource(worksSameResourcePk) {
    const url = `${this.worksSameResourcesUrl}/${worksSameResourcePk}/`;

    return this.dal.getter(url, "getDetailedWorksSameResource");
  }

  createWorksSameResource(worksSameResource) {
    const url = `${this.worksSameResourcesUrl}/`;

    return this.dal.poster(url, worksSameResource, "createWorksSameResource");
  }

  putWorksSameResource(worksSameResource) {
    const url = `${this.worksSameResourcesUrl}/${worksSameResource.pk}/`;

    return this.dal.putter(url, worksSameResource, "putWorksSameResource");
  }

  deleteWorksSameResource(worksSameResourcePk) {
    const url = `${this.worksSameResourcesUrl}/${worksSameResourcePk}/`;

    return this.dal.deleter(url, "deleteWorksSameResource");
  }

  //
  // WORKS RESOURCES CLAIMS
  //
  getDetailedWorksResourcesClaim(worksResourcesClaimPk) {
    const url = `${this.worksResourcesClaimsUrl}/${worksResourcesClaimPk}/`;

    return this.dal.getter(url, "getDetailedWorksResourcesClaim");
  }

  createWorksResourcesClaim(worksResourcesClaim) {
    const url = `${this.worksResourcesClaimsUrl}/`;

    return this.dal.poster(url, worksResourcesClaim, "createWorksResourcesClaim");
  }

  putWorksResourcesClaim(worksResourcesClaim) {
    const url = `${this.worksResourcesClaimsUrl}/${worksResourcesClaim.pk}/`;

    return this.dal.putter(url, worksResourcesClaim, "putWorksResourcesClaim");
  }

  deleteWorksResourcesClaim(worksResourcesClaimPk) {
    const url = `${this.worksResourcesClaimsUrl}/${worksResourcesClaimPk}/`;

    return this.dal.deleter(url, "deleteWorksResourcesClaim");
  }

  //
  // WORKS SPANS
  //
  getDetailedWorksSpan(worksSpanPk) {
    const url = `${this.worksSpansUrl}/${worksSpanPk}/`;

    return this.dal.getter(url, "getDetailedWorksSpan");
  }

  createWorksSpan(worksSpan) {
    const url = `${this.worksSpansUrl}/`;

    return this.dal.poster(url, worksSpan, "createWorksSpan");
  }

  putWorksSpan(worksSpan) {
    const url = `${this.worksSpansUrl}/${worksSpan.pk}/`;

    return this.dal.putter(url, worksSpan, "putWorksSpan");
  }

  deleteWorksSpan(worksSpanPk) {
    const url = `${this.worksSpansUrl}/${worksSpanPk}/`;

    return this.dal.deleter(url, "deleteWorksSpan");
  }

  //
  // WORKS CONDITIONAL
  //
  getDetailedWorksConditional(worksConditionalPk) {
    const url = `${this.worksConditionalsUrl}/${worksConditionalPk}/`;

    return this.dal.getter(url, "getDetailedWorksConditional");
  }

  createWorksConditional(worksConditional) {
    const url = `${this.worksConditionalsUrl}/`;

    return this.dal.poster(url, worksConditional, "createWorksConditional");
  }

  putWorksConditional(worksConditional) {
    const url = `${this.worksConditionalsUrl}/${worksConditional.pk}/`;

    return this.dal.putter(url, worksConditional, "putWorksConditional");
  }

  deleteWorksConditional(worksConditionalPk) {
    const url = `${this.worksConditionalsUrl}/${worksConditionalPk}/`;

    return this.dal.deleter(url, "deleteWorksConditional");
  }
}

export default WorkDal;
