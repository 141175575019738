//
// Action creators for PlanBoardPanel
//
import InternalDal from "app/utils/InternalDal";
import { getEmptyPromise } from "app/utils/dal";

/**
 * @typedef Action {{type: string, payload: *}}
 */

export const actionTypes = {
  CLEAR_PLANBOARD_VIEW: "planboard/CLEAR_PLANBOARD_VIEW",
  SELECT_ITEM: "planBoard/SELECT_ITEM",
  SET_PLANBOARD_URL: "planboard/SET_PLANBOARD_URL",
  SET_PLANBOARD_VIEW: "planboard/SET_PLANBOARD_VIEW",
  SET_PLANBOARD_PANEL_OPTION: "planboard/SET_PLANBOARD_PANEL_OPTION",
  // Settings
  TOGGLE_EMPTY_TIMELINES_RESOURCES: "planboard/TOGGLE_EMPTY_TIMELINES_RESOURCES",
  TOGGLE_EMPTY_TIMELINES_WORKS: "planboard/TOGGLE_EMPTY_TIMELINES_WORKS",
  TOGGLE_EMPTY_TIMELINES_WORKS_IF_FULLY_PLANNED: "planboard/TOGGLE_EMPTY_TIMELINES_WORKS_IF_FULLY_PLANNED",
  // Actual stuff
  CLEAR_ACTUAL_DATE_RANGES: "planboard/CLEAR_ACTUAL_DATE_RANGES",
  CLEAR_ACTUAL_DATE_RANGES_EXCEPT_CURRENT: "planboard/CLEAR_ACTUAL_DATE_RANGES_EXCEPT_CURRENT",
  CLEAR_ACTUAL_VIEW: "planboard/CLEAR_ACTUAL_VIEW",

  LOAD_ACTUAL_DATE_RANGE: "planboard/LOAD_ACTUAL_DATE_RANGE",
  LOAD_ACTUAL_DATE_RANGE_NOTES: "planboard/LOAD_ACTUAL_DATE_RANGE_NOTES",

  NAV_TO_ACTUAL_DATE: "planboard/NAV_TO_ACTUAL_DATE",
  SET_ACTUAL_VIEW: "planboard/SET_ACTUAL_VIEW",
};

const planBoardActionCreators = {
  clearPlanBoardView() {
    return {
      type: actionTypes.CLEAR_PLANBOARD_VIEW,
      payload: getEmptyPromise(),
    };
  },

  // Set all plan board URL parameters at once.
  // Using a single action prevents a XHR call after setting each one individually.
  setPlanBoardView(planBoardView) {
    return {
      type: actionTypes.SET_PLANBOARD_VIEW,
      payload: planBoardView,
    };
  },

  setPlanBoardUrl(planBoardUrl) {
    return {
      type: actionTypes.SET_PLANBOARD_URL,
      payload: planBoardUrl,
    };
  },

  setPlanBoardPanelOption(panelOption) {
    return {
      type: actionTypes.SET_PLANBOARD_PANEL_OPTION,
      payload: panelOption,
    };
  },

  toggleEmptyTimelinesResources(bool) {
    return {
      type: actionTypes.TOGGLE_EMPTY_TIMELINES_RESOURCES,
      payload: bool,
    };
  },

  toggleEmptyTimelinesWorks(bool) {
    return {
      type: actionTypes.TOGGLE_EMPTY_TIMELINES_WORKS,
      payload: bool,
    };
  },

  toggleEmptyTimelinesWorksIfFullyPlanned(bool) {
    return {
      type: actionTypes.TOGGLE_EMPTY_TIMELINES_WORKS_IF_FULLY_PLANNED,
      payload: bool,
    };
  },

  selectItem(selectedItem) {
    return {
      type: actionTypes.SELECT_ITEM,
      payload: selectedItem,
    };
  },

  unSelectItem() {
    return {
      type: actionTypes.SELECT_ITEM,
      payload: null,
    };
  },

  clearActualDateRanges() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_ACTUAL_DATE_RANGES,
      payload: actionPromise,
    };
  },

  clearActualDateRangesExceptCurrent() {
    return {
      type: actionTypes.CLEAR_ACTUAL_DATE_RANGES_EXCEPT_CURRENT,
      payload: getEmptyPromise(),
    };
  },

  clearActualView() {
    return {
      type: actionTypes.CLEAR_ACTUAL_VIEW,
      payload: getEmptyPromise(),
    };
  },

  loadActualDateRange(startDate, endDate) {
    // TODO extract this?
    const dal = new InternalDal();
    const queries = [
      `start=${encodeURIComponent(startDate.format())}`,
      `end=${encodeURIComponent(endDate.format())}`,
      `only_notes=${false}`,
    ];
    const url = dal.makeTenantAwareApiUrl("planboard");
    const urlWithQuery = `${url}?${queries.join("&")}`;

    return {
      type: actionTypes.LOAD_ACTUAL_DATE_RANGE,
      payload: dal.getter(urlWithQuery),
      // NOTE data is used in reducer!
      data: {
        start: startDate,
        end: endDate,
      },
    };
  },

  loadActualDateRangeNotes(startDate, endDate) {
    const dal = new InternalDal();
    const queries = [
      `start=${encodeURIComponent(startDate.format())}`,
      `end=${encodeURIComponent(endDate.format())}`,
      `only_notes=${true}`,
    ];
    const url = dal.makeTenantAwareApiUrl("planboard");
    const urlWithQuery = `${url}?${queries.join("&")}`;

    return {
      type: actionTypes.LOAD_ACTUAL_DATE_RANGE_NOTES,
      payload: dal.getter(urlWithQuery),
      // NOTE data is used in reducer!
      data: {
        start: startDate,
        end: endDate,
      },
    };
  },

  navToActualDate(date) {
    return {
      type: actionTypes.NAV_TO_ACTUAL_DATE,
      payload: date,
    };
  },

  setActualView(planBoardView) {
    return {
      type: actionTypes.SET_ACTUAL_VIEW,
      payload: planBoardView,
    };
  },
};

export default planBoardActionCreators;
