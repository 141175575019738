import { List } from "immutable";
import { ReactNode, createContext, useContext } from "react";

type FormOnChange = (value: never) => void;

export interface FieldProps {
  name: string;
  value: never;
  errors: List<string>;
  onChange: FormOnChange;
}

interface FormFieldsProviderProps {
  children: ReactNode;
  getFieldProps: (fieldName: string) => FieldProps;
}

export const defaultFormOnChange: FormOnChange = () => {
  // TODO only raise error in development
  // throw new Error("The form is missing an onChange handler.");
};

const defaultGetFieldProps = (fieldName: string): FieldProps => {
  throw new Error(`getFieldProps was called outside of FormFieldsProvider. Missing Provider? Field: ${fieldName}`);
};

const FormFieldsContext = createContext({ getFieldProps: defaultGetFieldProps });
const FormFieldsProvider = ({ children, getFieldProps }: FormFieldsProviderProps) => (
  <FormFieldsContext.Provider value={{ getFieldProps }}>{children}</FormFieldsContext.Provider>
);
export const useFormGetFieldProps = () => useContext(FormFieldsContext).getFieldProps;
export const useFormFieldProps = (fieldName: string) => useFormGetFieldProps()(fieldName);

export default FormFieldsProvider;
