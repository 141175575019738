//
// Action creators for resources app
//
/**
 * @typedef Action {type: string, payload: *}
 */
import { getEmptyPromise } from "app/utils/dal";
import ResourcesDal from "./dal";

export const actionTypes = {
  LOAD_SIMPLE_RESOURCES_ASYNC: "resources/LOAD_SIMPLE_RESOURCES_ASYNC",
  CLEAR_SIMPLE_RESOURCES: "resources/CLEAR_SIMPLE_RESOURCES",

  LOAD_DETAILED_RESOURCE_ASYNC: "resources/LOAD_DETAILED_RESOURCE_ASYNC",
  CLEAR_DETAILED_RESOURCE: "resources/CLEAR_DETAILED_RESOURCE",

  CLONE_RESOURCE_ASYNC: "resources/CLONE_RESOURCE_ASYNC",
  CREATE_RESOURCE: "resources/CREATE_RESOURCE",
  UPDATE_RESOURCE: "resources/UPDATE_RESOURCE",
  UPDATE_RESOURCE_SKILLS: "resources/UPDATE_RESOURCE_SKILLS",
  DELETE_RESOURCE: "resources/DELETE_RESOURCE",

  LOAD_SIMPLE_BREEDS: "resources/LOAD_SIMPLE_BREEDS",
  CLEAR_SIMPLE_BREEDS: "resources/CLEAR_SIMPLE_BREEDS",
  LOAD_DETAILED_BREED: "resources/LOAD_DETAILED_BREED",
  CLEAR_DETAILED_BREED: "resources/CLEAR_DETAILED_BREED",
  CREATE_BREED: "resources/CREATE_BREED",
  UPDATE_BREED: "resources/UPDATE_BREED",
  UPDATE_BREED_RESOURCES: "resources/UPDATE_BREED_RESOURCES",
  DELETE_BREED: "resources/DELETE_BREED",

  // NOTE: action is in calendars, reducers in resources.
  LOAD_COLORSLOTS_RESOURCE_PREVIEW_ASYNC: "resources/LOAD_COLORSLOTS_RESOURCE_PREVIEW_ASYNC",
};

const resourcesActionCreators = {
  /***
   * Load all simple resources
   * @returns {Action}
   */
  loadSimpleResources() {
    const dal = new ResourcesDal();
    const actionPromise = dal.getSimpleResources();
    return {
      type: actionTypes.LOAD_SIMPLE_RESOURCES_ASYNC,
      payload: actionPromise,
    };
  },

  clearSimpleResources() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_RESOURCES,
      payload: actionPromise,
    };
  },

  /***
   * Loads detailed resource
   * @param resourcePk {number}
   * @returns {Action}
   */
  loadDetailedResource(resourcePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.getDetailedResource(resourcePk);
    return {
      type: actionTypes.LOAD_DETAILED_RESOURCE_ASYNC,
      payload: actionPromise,
    };
  },

  clearDetailedResource() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_RESOURCE,
      payload: actionPromise,
    };
  },

  /***
   * Clones resource
   * @param pk
   * @returns {Action}
   */
  cloneResource(pk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.cloneResource(pk);
    return {
      type: actionTypes.CLONE_RESOURCE_ASYNC,
      payload: actionPromise,
    };
  },

  /***
   * Creates resources
   * @param resource
   * @returns {Action}
   */
  createResource(resource) {
    const dal = new ResourcesDal();
    const actionPromise = dal.createResource(resource);
    return {
      type: actionTypes.CREATE_RESOURCE,
      payload: actionPromise,
    };
  },

  /***
   * Updates resource
   * @param resource
   * @returns {Action}
   */
  updateResource(resource) {
    const dal = new ResourcesDal();
    const actionPromise = dal.putResource(resource);
    return {
      type: actionTypes.UPDATE_RESOURCE,
      payload: actionPromise,
    };
  },

  /***
   * Update skills of Resource
   * @param resourcePk {number}
   * @param skillPks {[number]}
   * @returns {Action}
   */
  updateResourceSkills(resourcePk, skillPks) {
    const dal = new ResourcesDal();
    const actionPromise = dal.patchResourceSkills(resourcePk, skillPks);
    return {
      type: actionTypes.UPDATE_RESOURCE_SKILLS,
      payload: actionPromise,
    };
  },

  /***
   * Deletes resource
   * @param resourcePk {number}
   * @returns {Action}
   */
  deleteResource(resourcePk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.deleteResource(resourcePk);
    return {
      type: actionTypes.DELETE_RESOURCE,
      payload: actionPromise,
    };
  },

  //
  // BREEDS
  //

  loadSimpleBreeds() {
    const dal = new ResourcesDal();
    const actionPromise = dal.getSimpleBreeds();
    return {
      type: actionTypes.LOAD_SIMPLE_BREEDS,
      payload: actionPromise,
    };
  },

  clearSimpleBreeds() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_BREEDS,
      payload: actionPromise,
    };
  },

  loadDetailedBreed(breedPk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.getDetailedBreed(breedPk);
    return {
      type: actionTypes.LOAD_DETAILED_BREED,
      payload: actionPromise,
    };
  },

  clearDetailedBreed() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_BREED,
      payload: actionPromise,
    };
  },

  createBreed(breed) {
    const dal = new ResourcesDal();
    const actionPromise = dal.createBreed(breed);
    return {
      type: actionTypes.CREATE_BREED,
      payload: actionPromise,
    };
  },

  updateBreed(breed) {
    const dal = new ResourcesDal();
    const actionPromise = dal.putBreed(breed);
    return {
      type: actionTypes.UPDATE_BREED,
      payload: actionPromise,
    };
  },

  updateBreedResources(breedPk, resourcePks) {
    const dal = new ResourcesDal();
    const actionPromise = dal.patchBreedResources(breedPk, resourcePks);
    return {
      type: actionTypes.UPDATE_BREED_RESOURCES,
      payload: actionPromise,
    };
  },

  deleteBreed(breedPk) {
    const dal = new ResourcesDal();
    const actionPromise = dal.deleteBreed(breedPk);
    return {
      type: actionTypes.DELETE_BREED,
      payload: actionPromise,
    };
  },
};

export default resourcesActionCreators;
