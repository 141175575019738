import { fromJS } from "immutable";
import { notLoadedMap } from "app/utils/constants";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import {
  keyArrToClearHandler,
  keyArrToLoadDetailedHandler,
  keyArrToLoadSimpleHandler,
  State,
} from "app/utils/redux/reducerHandlers";
import { Action } from "app/utils/redux/standardActions";
import { actionTypes } from "./actions";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
});

function labelsReducer(state: State = initialState, action: Action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_LABELS]: keyArrToLoadSimpleHandler(["all"], "label"),
    [actionTypes.CLEAR_SIMPLE_LABELS]: keyArrToClearHandler(["all"]),
    [actionTypes.LOAD_DETAILED_LABEL]: keyArrToLoadDetailedHandler(["detailed"]),
    [actionTypes.CLEAR_DETAILED_LABEL]: keyArrToClearHandler(["detailed"]),
  });
}

export default labelsReducer;
