import { fromJS } from "immutable";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";

// TODO get rid of all this! It should not be used anymore

const initialCalculationsState = fromJS({
  // TODO restructure review: {results, iterationNumber etc.}
  calc_out: notLoadedMap,
  iterations: notLoadedMap,
  stats: notLoadedMap,
});

const initialState = fromJS({
  calculation: initialCalculationsState,
});

function handleReviewIterations(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["calculation", "iterations"], isLoadingMap);
  }

  if (action.status === "done") {
    const iterationsJS = action.payload.body.results;
    state = state.setIn(["calculation", "iterations"], fromJS(iterationsJS));
  }

  return state;
}

function handleReviewOut(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["calculation", "calc_out"], isLoadingMap);
  }

  if (action.status === "done") {
    const statsJS = action.payload.body.results;
    state = state.setIn(["calculation", "calc_out"], fromJS(statsJS));
  }

  return state;
}

function handleReviewStats(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["calculation", "stats"], isLoadingMap);
  }

  if (action.status === "done") {
    const statsJS = action.payload.body.results;
    state = state.setIn(["calculation", "stats"], fromJS(statsJS));
  }

  return state;
}

function handleClearCalculationResults(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state
      .setIn(["calculation", "review"], notLoadedMap)
      .setIn(["calculation", "iterations"], notLoadedMap)
      .setIn(["calculation", "stats"], notLoadedMap);
  }

  return state;
}

//
// Plan tools reducer
//
function planToolsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.REVIEW_CALCULATION_ITERATIONS]: handleReviewIterations,
    [actionTypes.REVIEW_CALCULATION_OUT]: handleReviewOut,
    [actionTypes.REVIEW_CALCULATION_STATS]: handleReviewStats,
    [actionTypes.CLEAR_CALCULATION_REVIEW]: handleClearCalculationResults,
  });
}

export default planToolsReducer;
