import classNames from "classnames";
import { List, fromJS, is } from "immutable";
import { debug } from "loglevel";
import PropTypes from "prop-types";
import { Component } from "react";
import { defineMessages } from "react-intl";
import { Prompt } from "react-router-dom";
import DefaultFormDebug from "app/common/forms/FormDebug";
import DefaultFormErrors from "app/common/forms/FormErrors";
import FormFieldsProvider from "app/common/forms/FormFieldsProvider";
import { safeFromJS, safeToJS } from "app/common/hooks/useSafeToJS";
import Joi from "app/utils/CustomJoi";
import { emptyFunc, emptyList, emptyMap } from "app/utils/constants";
import { useFormatMessage } from "app/utils/intl";

const messages = defineMessages({
  confirmUnsavedChanges: {
    id: "FormWrapper.confirmUnsavedChanges",
    defaultMessage: "You have unsaved changes. Are you sure you want to leave this page?",
  },
});

const defaultFormOpts = {
  debug: false,
  wrapperElement: null,
  allowUnknown: true,
  stripUnknown: false,
  refreshOnNewValues: false,
};

const getInitialStateFromInitialValues = (initialValues) => ({
  // validation errors are generated and managed internally,
  // so we want to separate them from outside errors in props
  validationErrors: emptyList,
  values: initialValues,
  valuesInitial: initialValues,
  modified: false,
  saving: false,
  _validated: false,
  cleanedData: undefined,
});

const validateFormComponentSchema = (FormComponent) => {
  const { schema, displayName } = FormComponent;

  if (!schema) {
    debug(`WARNING: form ${displayName} does not have a schema.`);
  } else if (!schema.isJoi) {
    debug(`WARNING: form ${displayName} does not have a proper Joi schema.
        Make sure you use Joi.object().keys()`);
  } else {
    // TODO figure out a way to check if all provided field names have schemaKeys
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const schemaKeys = schema._inner.children.map((c) => c.key);
  }
};

const LeavingUnsavedFormPrompt = ({ modified, saving }) => {
  const formatMessage = useFormatMessage();
  const showUnsavedChangesMessage = modified && !saving;
  const confirmUnsavedChangesMessage = formatMessage(messages.confirmUnsavedChanges);

  return <Prompt message={confirmUnsavedChangesMessage} when={showUnsavedChangesMessage} />;
};

//
// Form wrapper
//
// Provides event handlers for different special fields.
// Expects function handleChangeField(event, attrName) to be implemented.
//
// Params:
//  FormComponent: wrapped form component
//  opts: {
//    validators: {fields: function} map
//  }
//
function formWrapper(FormComponent, formOpts = {}) {
  const opts = { ...defaultFormOpts, ...formOpts };

  // return a wrapper class that wraps given form-component

  // First we validate the schema of the FormComponent and warn for inconsistencies
  validateFormComponentSchema(FormComponent);

  //
  // Form component
  // Keeps its own temporary state while editing.
  //
  // Props are JS objects, all internal data is mutable
  //
  // Props:
  //    errors: object of errors, like {fieldName: [errors]} (optional)
  //    values: object of values, like {fieldName: value} (optional)
  //
  //    errorListElement: node to override the error list part (optional)
  //
  // Callbacks:
  //    onChange: one field's value has been changed (optional)
  //    onSubmit: the form is complete (optional)
  //    onCancel: the form wants to cancel (optional)
  //
  class FormWrapper extends Component {
    constructor(props) {
      super(props);

      const initialValues = safeFromJS(props.values);
      this.state = getInitialStateFromInitialValues(initialValues);
    }

    componentDidUpdate() {
      if (opts.refreshOnNewValues) {
        this.refreshInitialValues();
      }
    }

    //
    // Handlers
    //

    //
    // Clean and validate data.
    //
    // Params:
    //  schema: joi schema
    //  data: object
    //
    // Returns:
    //  {
    //    cleanedData:object,
    //    validationErrors: object like {fieldName: errorMessage}
    //  }
    //
    static validateDataWithSchema(schema, data) {
      // Only validate when there is a schema
      if (!schema) {
        return null;
      }

      const dataJS = safeToJS(data);
      const validationOptions = {
        // we want to see all errors all the time
        abortEarly: false,
        // we don't care if the data has extra keys
        allowUnknown: opts.allowUnknown,
        stripUnknown: opts.stripUnknown,
      };
      const validationResult = Joi.validate(dataJS, schema, validationOptions);
      const validationErrorDetails = validationResult.error ? validationResult.error.details : [];
      const validationErrors = validationErrorDetails.map((fieldError) => [fieldError.path, [fieldError.message]]);

      return {
        cleanedData: validationResult.value,
        validationErrors: Object.fromEntries(validationErrors),
        hasValidationErrors: validationErrors.length > 0,
      };
    }

    handleSubmit = () => {
      this.setState({ saving: true }, () => {
        const validatedState = this.validateValues(this.state.values);
        if (validatedState.hasValidationErrors) {
          this.setState(validatedState);
        } else {
          this.props.onSubmit({
            cleanedData: validatedState.cleanedData,
            rawData: validatedState.values.toJS(),
          });
        }
      });
    };

    //
    // Handler for field value change
    // Updates each field's value in state.
    //
    // TODO rename to handleChangeFields
    handleFieldChange = (changes) => {
      const oldValues = this.state.values;
      let newValues = oldValues;
      Object.entries(changes).forEach(([fieldName, value]) => {
        newValues = newValues.set(fieldName, value);
      });

      // When one field changes, the FormComponent has the chance of running its own newValuesOnChange
      // This way, form instances can implement logic that changes live values, before submit
      // e.g. change endDate depending on startDate
      // newValuesOnChange intercepts the changes and can modify them before they are applied.
      // We do this before validation, so the form component can clean up invalid values before they are validated.

      // Form instances' newValuesOnChange functions are pure functions that take the state
      // and return a new state object.
      if (FormComponent.newValuesOnChange) {
        const newValuesResult = FormComponent.newValuesOnChange(newValues, changes, oldValues);
        // newValuesOnChange can return undefined, in which case it will be ignored
        newValues = newValuesResult ?? newValues;
      }

      const newValidatedState = this.validateValues(newValues);
      const oldValidatedState = this.validateValues(oldValues);

      this.setState(newValidatedState, () => this.props.onChange(newValidatedState, oldValidatedState));
    };

    refreshInitialValues = () => {
      const initialValues = safeFromJS(this.props.values);
      const initialValuesState = getInitialStateFromInitialValues(initialValues);
      if (!is(initialValuesState.valuesInitial, this.state.valuesInitial)) {
        const initialValuesValidatedState = this.validateValues(initialValues);
        this.setState({
          ...initialValuesState,
          ...initialValuesValidatedState,
        });
      }
    };

    validateValues = (values = this.state.values) => {
      const modified = !is(values, safeFromJS(this.props.values));

      // This allows us to pass in a custom schema
      const formSchema = this.props.schema ?? FormComponent.schema;
      const formValidatedState = {
        _validated: false,
        cleanedData: undefined,
        validationErrors: emptyMap,
        hasValidationErrors: false,

        values,
        modified,
      };

      // It might happen that values is not given here...
      const canValidate = formSchema && values;
      if (canValidate) {
        const validationResult = FormWrapper.validateDataWithSchema(formSchema, values);
        if (validationResult) {
          const { cleanedData, validationErrors, hasValidationErrors } = validationResult;

          formValidatedState._validated = true;
          if (hasValidationErrors) {
            formValidatedState.validationErrors = fromJS(validationErrors);
            formValidatedState.hasValidationErrors = true;
          } else {
            formValidatedState.cleanedData = cleanedData;
          }
        }
      }

      return formValidatedState;
    };

    // Function given to wrapped form, to use on Field components
    // Provides props for field, based on name.
    getFieldProps = (fieldName) => {
      // Combine errors from props and validation
      const fieldValidationErrors = this.state.validationErrors.get(fieldName, emptyList);
      const fieldPropsErrors = this.props.errors[fieldName] ? List(this.props.errors[fieldName]) : emptyList;
      const fieldErrors = fieldPropsErrors.merge(fieldValidationErrors);

      return {
        name: fieldName,
        errors: fieldErrors,
        value: this.state.values?.get(fieldName),
        onChange: this.handleFieldChange,
      };
    };

    //
    // Render
    //
    // raw values and errors are passed down to FormComponent
    // even though they are probably never used
    // so that FormComponent re-renders when they change
    render() {
      const { onChange, onCancel, warnOnLeavingUnsavedForm } = this.props;
      const { modified, saving } = this.state;

      const formErrors = this.props.errors.form; // TODO currently never passed in, so not used?
      const numErrors = formErrors === undefined ? 0 : formErrors.length;

      const FormDebugComponent = this.props.debugElement || DefaultFormDebug;
      const ErrorsComponent = this.props.errorListElement || DefaultFormErrors;

      const content = (
        <FormFieldsProvider getFieldProps={this.getFieldProps}>
          <ErrorsComponent errors={formErrors} />
          <FormDebugComponent formState={this.state} />
          <FormComponent
            {...this.props}
            valuesInitial={this.state.valuesInitial}
            values={this.state.values}
            errors={this.state.errors}
            getFieldProps={this.getFieldProps}
            onChangeFields={this.handleFieldChange}
            onChange={onChange}
            onSubmit={this.handleSubmit}
            onCancel={onCancel}
          />

          {warnOnLeavingUnsavedForm && <LeavingUnsavedFormPrompt modified={modified} saving={saving} />}
        </FormFieldsProvider>
      );

      const { wrapperElement } = opts;
      if (wrapperElement === "React.Fragment") {
        // Already wrapped with Fragment above.
        return content;
      }

      const className = classNames("form-wrapper", { "has-errors": numErrors });
      const Wrapper = wrapperElement || "div";
      return <Wrapper className={className}>{content}</Wrapper>;
    }
  }

  FormWrapper.propTypes = formWrapperPropTypes;
  FormWrapper.defaultProps = formWrapperDefaultProps;

  return FormWrapper;
}

const formWrapperDefaultProps = {
  errors: {
    form: [],
  },
  onChange: emptyFunc,
  values: {},

  warnOnLeavingUnsavedForm: true,
};

const formWrapperPropTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,

  // joi object schema for submitted object
  schema: PropTypes.instanceOf(Joi),

  // overriding template parts
  debugElement: PropTypes.node,
  errorListElement: PropTypes.node,

  // handlers
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,

  warnOnLeavingUnsavedForm: PropTypes.bool,
};

export default formWrapper;
