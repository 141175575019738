import whyDidYouRender from "@welldone-software/why-did-you-render";
import React from "react";
import ReactDOM from "react-dom";
import { initialiseBlueBirdPromise } from "app/core/bluebird";
import App from "app/core/components/App";
import { initialiseSentryErrorReporting } from "app/core/sentry";
import { initialiseFirebaseAuth } from "app/features/auth/firebase";
import store from "app/store";
import { debugWhyDidYouRender } from "../process";

//
// Renders app to given DOM element
//
function initialiseApp(elementId: string) {
  if (debugWhyDidYouRender) {
    whyDidYouRender(React);
  }

  initialiseSentryErrorReporting();
  initialiseFirebaseAuth();
  initialiseBlueBirdPromise();

  // Use ReactDOM to render the app to the given element
  const renderAppToElement = () => {
    const app = <App store={store} />;
    const element = document.getElementById(elementId);
    ReactDOM.render(app, element);
  };

  // Actually render the App
  renderAppToElement();

  // Hot Module Replacement API
  if (module.hot) {
    module.hot.accept("app/core/components/App", renderAppToElement);
  }
}

export default initialiseApp;
