import { getEmptyPromise } from "app/utils/dal";
import SkillsDal from "./dal";

export const actionTypes = {
  LOAD_SIMPLE_SKILLS_ASYNC: "skills/LOAD_SIMPLE_SKILLS_ASYNC",
  CLEAR_SIMPLE_SKILLS: "skills/CLEAR_SIMPLE_SKILLS",
  LOAD_DETAILED_SKILL_ASYNC: "skills/LOAD_DETAILED_SKILL_ASYNC",
  CLEAR_DETAILED_SKILL: "skills/CLEAR_DETAILED_SKILL",
  CREATE_SKILL: "skills/CREATE_SKILL",
  UPDATE_SKILL: "skills/UPDATE_SKILL",
  DELETE_SKILL: "skills/DELETE_SKILL",
  UPDATE_SKILL_RESOURCES: "skills/UPDATE_SKILL_RESOURCES",
};

/***
 * Action creators for skills
 * @type {Object}
 */
const actionCreators = {
  //
  // Load all simple skills
  //
  loadSimpleSkills() {
    const dal = new SkillsDal();
    const actionPromise = dal.getSimpleSkills();
    return {
      type: actionTypes.LOAD_SIMPLE_SKILLS_ASYNC,
      payload: actionPromise,
    };
  },

  clearSimpleSkills() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_SIMPLE_SKILLS,
      payload: actionPromise,
    };
  },

  //
  // Load detailed skill
  //
  loadDetailedSkill(skillPk) {
    const dal = new SkillsDal();
    const actionPromise = dal.getDetailedSkill(skillPk);

    return {
      type: actionTypes.LOAD_DETAILED_SKILL_ASYNC,
      payload: actionPromise,
    };
  },

  clearDetailedSkill() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_SKILL,
      payload: actionPromise,
    };
  },

  createSkill(skill) {
    const dal = new SkillsDal();
    const actionPromise = dal.createSkill(skill);
    return {
      type: actionTypes.CREATE_SKILL,
      payload: actionPromise,
    };
  },

  updateSkill(skill) {
    const dal = new SkillsDal();
    const actionPromise = dal.putSkill(skill);
    return {
      type: actionTypes.UPDATE_SKILL,
      payload: actionPromise,
    };
  },

  deleteSkill(skillPk) {
    const dal = new SkillsDal();
    const actionPromise = dal.deleteSkill(skillPk);
    return {
      type: actionTypes.DELETE_SKILL,
      payload: actionPromise,
    };
  },

  updateSkillResources(skillPk, resourcePks) {
    const dal = new SkillsDal();
    const actionPromise = dal.patchSkillResources(skillPk, resourcePks);
    return {
      type: actionTypes.UPDATE_SKILL_RESOURCES,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
