import { EuiButton } from "@elastic/eui";
import { FormattedMessage } from "react-intl";
import FirebaseAuthFlowFooter from "app/features/auth/components/FirebaseAuthFlowFooter";
import FirebaseUIDiv from "app/features/auth/components/FirebaseUIDiv";
import { LoginPanel } from "app/features/auth/components/Login";

interface FirebaseAuthFlowWrongDomainProps {
  tenantName: string;
  redirectUrl: string;
  correctDomainUrl: string;
  correctDomain: string;
  wrongDomain: string;
}

const FirebaseAuthFlowWrongDomain = ({
  tenantName,
  redirectUrl,
  correctDomainUrl,
  correctDomain,
  wrongDomain,
}: FirebaseAuthFlowWrongDomainProps) => (
  <div style={{ margin: "auto" }}>
    <LoginPanel
      content={
        <div style={{ width: "100%" }}>
          <FirebaseUIDiv isHidden={true} />
          <div
            style={{
              paddingTop: 40,
              textAlign: "center",
            }}
          >
            <FormattedMessage id="FirebaseAuthFlowWrongDomain.currentlyUnavailable" />
            <br />
            <span style={{ textDecoration: "line-through" }}>
              <b>{wrongDomain}</b>
            </span>
            <br />
            <FormattedMessage id="FirebaseAuthFlowWrongDomain.useTheFollowingDomain" />
            <br />
            <b>{correctDomain}</b>
          </div>

          <div
            style={{
              paddingTop: 40,
              textAlign: "center",
            }}
          >
            <EuiButton fill size="m" href={correctDomainUrl}>
              <FormattedMessage id="FirebaseAuthFlowWrongDomain.goToCorrectDomain" />
            </EuiButton>
          </div>
        </div>
      }
      footer={<FirebaseAuthFlowFooter tenantName={tenantName} redirectUrl={redirectUrl} />}
    />
  </div>
);

export default FirebaseAuthFlowWrongDomain;
