import InternalDal from "app/utils/InternalDal";

//
// Data access layer for work planning tools
//
class SnapshotsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.snapshotsUrl = `${this.dal.baseTenantUrl}/snapshots`;
    this.snapshotScopesUrl = `${this.dal.baseTenantUrl}/snapshot-scopes`;
  }

  getSnapshot(pk) {
    const url = `${this.snapshotsUrl}/${pk}/`;

    return this.dal.getter(url, "getSnapshot");
  }

  loadLastCompletedSnapshot(snapshotScopePk, full = false) {
    // TODO
    //  We should never have to call this if we first poll the last completed pk, we can just load the snapshot
    //  by pk after polling...
    const queryParams = [
      "actual=1",
      snapshotScopePk ? `scope=${snapshotScopePk}` : null,
      full ? "dto=1" : "validation=1",
    ].filter(Boolean);
    const url = `${this.snapshotsUrl}/last/?${queryParams.join("&")}`;

    return this.dal.getter(url, "loadLastCompletedSnapshot");
  }

  pollLastCompletedSnapshot(snapshotScopePk) {
    const url = `${this.snapshotsUrl}/last/${snapshotScopePk ? `?scope=${snapshotScopePk}` : ""}`;

    return this.dal.getter(url, "pollLastCompletedSnapshot");
  }

  getDownloadSnapshotResultsUrl(snapshotId) {
    return `${this.snapshotsUrl}/download?snapshot=${snapshotId}`;
  }
}

export default SnapshotsDal;
