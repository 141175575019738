import { fromJS } from "immutable";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import { AssignmentDetailedRecord } from "app/utils/records";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "app/features/work/actions";

const initialState = fromJS({
  detailed: notLoadedMap,
});

function handleLoadDetailedAssignmentAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const assignmentJS = action.payload.body;
    let assignmentMap = fromJS(assignmentJS);
    const actual = assignmentMap.get("details_actual");
    const planned = assignmentMap.get("details_planned");
    const realised = assignmentMap.get("details_realised");

    assignmentMap = assignmentMap.merge({
      start: actual.get("start"),
      end: actual.get("end"),
      minutes: actual.get("minutes"),
      minutes_work: actual.get("minutes_work"),
      minutes_work_custom: actual.get("minutes_work_custom"),
      units: actual.get("units"),
      units_custom: actual.get("units_custom"),
      location: actual.get("location"),
      resources: actual.get("resources"),
      stock_items: actual.get("stock_items"),

      planned_start: planned.get("start"),
      planned_end: planned.get("end"),
      planned_minutes: planned.get("minutes"),
      planned_minutes_work: planned.get("minutes_work"),
      planned_minutes_work_custom: planned.get("minutes_work_custom"),
      planned_units: planned.get("units"),
      planned_units_custom: planned.get("units_custom"),
      planned_location: planned.get("location"),
      planned_resources: planned.get("resources"),
      planned_stock_items: planned.get("stock_items"),

      realised_start: realised.get("start"),
      realised_end: realised.get("end"),
      realised_minutes: realised.get("minutes"),
      realised_minutes_work: realised.get("minutes_work"),
      realised_minutes_work_custom: realised.get("minutes_work_custom"),
      realised_units: realised.get("units"),
      realised_units_custom: realised.get("units_custom"),
      realised_location: realised.get("location"),
      realised_resources: realised.get("resources"),
      realised_stock_items: realised.get("stock_items"),
    });

    assignmentMap = AssignmentDetailedRecord(assignmentMap);
    state = state.set("detailed", assignmentMap);
  }
  return state;
}

function handleClearDetailedAssignment(prevState, _action) {
  return prevState.set("detailed", notLoadedMap);
}

//
// Assignment collection reducer
//
function assignmentsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_ASSIGNMENT_ASYNC]: handleLoadDetailedAssignmentAsync,
    [actionTypes.CLEAR_DETAILED_ASSIGNMENT]: handleClearDetailedAssignment,
  });
}

export default assignmentsReducer;
