import { useIntl } from "react-intl";

interface FormattedMessageArgs {
  id: string;
  values?: Record<string, string>;
}

export const useFormatMessage = () => useIntl().formatMessage;

export const useFormattedMessage = ({ id, values, ...otherDescriptorProps }: FormattedMessageArgs) =>
  useIntl().formatMessage({ id, ...otherDescriptorProps }, values);

// This is a fallback for missing translations, makes it easier to spot missing translations in development.
export const missingTranslation = (s: string) => s;
