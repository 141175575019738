import InternalDal from "app/utils/InternalDal";

class LocationsDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.locationsUrl = `${this.dal.baseTenantUrl}/locations`;
    this.locationsWithTravelTimesUrl = `${this.dal.baseTenantUrl}/locations-travel-times`;
    this.travelTimesUrl = `${this.dal.baseTenantUrl}/travel-times`;
  }

  createLocation(location) {
    const url = `${this.locationsUrl}/`;

    return this.dal.poster(url, location, "createLocation");
  }

  updateLocation(location) {
    const locationPk = location.pk;
    const url = `${this.locationsUrl}/${locationPk}/`;

    return this.dal.putter(url, location, "updateLocation");
  }

  deleteLocation(locationPk) {
    const url = `${this.locationsUrl}/${locationPk}/`;

    return this.dal.deleter(url, "deleteLocation");
  }

  loadSimpleLocations() {
    const url = `${this.locationsUrl}/`;

    return this.dal.getter(url, "loadSimpleLocations");
  }

  loadDetailedLocation(locationPk) {
    const url = `${this.locationsUrl}/${locationPk}/`;

    return this.dal.getter(url, "loadDetailedLocation");
  }

  loadTravelTimes(locationFromPks, locationToPks) {
    const queryLocationTo = locationToPks.map((locationPk) => `location_to=${locationPk}`).join("&");
    const queryLocationFrom = locationFromPks.map((locationPk) => `location_from=${locationPk}`).join("&");
    const queryString = `?${queryLocationTo}&${queryLocationFrom}`;
    const url = `${this.travelTimesUrl}/${queryString}`;

    return this.dal.getter(url, "loadTravelTimes");
  }

  loadMissingTravelTimes() {
    const url = `${this.travelTimesUrl}/missing`;

    return this.dal.getter(url, "loadMissingTravelTimes");
  }

  calculateMissingTravelTimes() {
    // TODO it should be possible to calculate missing travel times for a subset of the locations

    const url = `${this.dal.baseTenantUrl}/calculate-missing-travel-times/`;
    const data = {};

    return this.dal.poster(url, data, "calculateMissingTravelTimes");
  }
}

export default LocationsDal;
