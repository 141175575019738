import classNames from "classnames";
import { List, Map, OrderedSet, Set, fromJS } from "immutable";
import { CSSProperties, ReactNode, useMemo } from "react";
import { defineMessages } from "react-intl";
import { useFormatMessage } from "app/utils/intl";

export const emptyList = List();
export const emptyMap = Map();
export const emptySet = Set();
export const emptyOrderedSet = OrderedSet();

// TODO extract
export const emptyResourceFilter = fromJS({
  query: "",
  breeds: emptySet,
  labels: emptySet,
  resources: emptySet,
  assignmentLabels: emptySet,
  assignmentProjects: emptySet,
  assignmentWorks: emptySet,
});

export const emptyFunc = () => {};
export const nullFunc = () => null;
export const trueFunc = () => true;
export const falseFunc = () => false;
export const identityFunc = (x: unknown) => x;

export const isLoadingKey = "isLoading";
export const isLoadingMap = fromJS({ [isLoadingKey]: true });
export const notLoadedKey = "notLoaded";
export const notLoadedMap = fromJS({ [notLoadedKey]: true });

// @ts-expect-error TODO fix this
export const isMapNotLoaded = (o) => o && o.get(notLoadedKey);
// @ts-expect-error TODO fix this
export const isMapLoading = (o) => o?.get(isLoadingKey);
// @ts-expect-error TODO fix this
export const isMapNotReady = (o) => !o || isMapNotLoaded(o) || isMapLoading(o);
// @ts-expect-error TODO fix this
export const isMapReady = (o) => !isMapNotReady(o);
// @ts-expect-error TODO fix this
export const areMapsLoading = (l) => l.some(isMapLoading);
// @ts-expect-error TODO fix this
export const areMapsNotReady = (l) => l.some(isMapNotReady);

export const getIconClassName = (icon: string) => `fa fa-${icon}`;

interface CustomIconProps {
  icon: string;
  style?: CSSProperties;
  className?: string | boolean | null;
}

interface CustomIconWithLabelProps extends CustomIconProps {
  label: ReactNode;
}

export const CustomIcon = ({ icon, style = {}, className = null }: CustomIconProps) => (
  <i className={classNames([getIconClassName(icon), className])} style={style} />
);

export const CustomIconWithLabel = ({ icon, style, className, label }: CustomIconWithLabelProps) => (
  <>
    <CustomIcon icon={icon} style={style} className={className} />
    &nbsp;{label}
  </>
);

// TODO
//  - complement other itemTypes

export const ItemTypes = {
  // Items
  assignment: "assignment",
  assignmentBlock: "assignmentBlock",
  calendarEvent: "calendarEvent",
  entity: "entity",
  planningNote: "planningNote",
  project: "project",
  resource: "resource",
  resourceChangeover: "resourceChangeover",
  resourceClaim: "resourceClaim",
  resourceTravel: "resourceTravel",
  stockItem: "stockItem",
  storage: "storage",
  work: "work",
  workGroup: "workGroup",
  // Slots
  breakingSlot: "breakingSlot",
  colorSlot: "colorSlot",
  deactivatedSlot: "deactivatedSlot",
  feasibleSlot: "feasibleSlot",
  freeSlot: "freeSlot",
  heatSlot: "heatSlot",
};

export const Icons = {
  arrowRight: "arrow-right",
  home: "house",
  link: "link",
  unlink: "unlink",
  clean: "broom",
  hide: "eye-slash",
  show: "eye",
  label: "tags",
  download: "download",
  fileUpload: "file-upload",
  check: "check",
  pause: "pause",
  play: "play",
  add: "plus",
  remove: "minus",
  expand: "plus",
  collapse: "minus",
  calculation: "calculator",
  clear: "times",
  clone: "copy",
  lock: "lock",
  unlock: "lock-open",
  exception: "etsy",
  delete: "trash",
  edit: "pencil",
  planboard: "calendar",
  actualPlanning: "calendar",
  publishedPlanning: "calendar-check",
  manage: "cog",
  note: "sticky-note",
  settings: "cog",
  work: "list-ul",
  changelog: "list",
  project: "list-ul",
  skill: "list-ul",
  resource: "cog",
  resources: "users",
  resourceBreed: "cog",
  resourceChangeovers: "users-cog",
  resourceHuman: "user",
  stock: "box",
  storage: "warehouse",
  network: "link",
  back: "long-arrow-left",
  save: "floppy-o",
  copy: "copy",
  block: "th",
  calendars: "calendar-o",
  skills: "wrench",
  user: "user-circle-o",
  tenant: "building",
  tenants: "city",
  travel: "car",
  profile: "user",
  logout: "sign-out",
  location: "map-pin",
  realised: "clock",
  refresh: "refresh",
  warning: "exclamation-triangle",
  snapshot: "clock",
  capacity: "thermometer-half",
  report: "chart-pie",
  chevronUp: "chevron-up",
  chevronDown: "chevron-down",
  chevronRight: "chevron-right",
  webSocket: "plug",
};

export const colorChoiceMessages = defineMessages({
  green: {
    id: "colorChoices.green",
    defaultMessage: "Green",
  },
  orange: {
    id: "colorChoices.orange",
    defaultMessage: "Orange",
  },
  red: {
    id: "colorChoices.red",
    defaultMessage: "Red",
  },
});

export const useColorChoices = () => {
  const formatMessage = useFormatMessage();
  return useMemo(
    () => [
      {
        value: "green",
        label: formatMessage(colorChoiceMessages.green),
      },
      {
        value: "orange",
        label: formatMessage(colorChoiceMessages.orange),
      },
      {
        value: "red",
        label: formatMessage(colorChoiceMessages.red),
      },
    ],
    [formatMessage],
  );
};

export const useColorLabel = (color: string) => {
  const formatMessage = useFormatMessage();
  // @ts-expect-error TODO fix this
  const colorMsg = colorChoiceMessages[color];
  return colorMsg ? formatMessage(colorMsg) : "";
};

export const useColorChoicesWithoutOrange = () => {
  const formatMessage = useFormatMessage();
  return useMemo(
    () => [
      {
        value: "green",
        label: formatMessage(colorChoiceMessages.green),
      },
      {
        value: "red",
        label: formatMessage(colorChoiceMessages.red),
      },
    ],
    [formatMessage],
  );
};

export const precedenceTypeChoiceMessages = defineMessages({
  end_after_end: {
    id: "PrecedenceTypeChoices.end_after_end",
    defaultMessage: "Successor ends after Predecessor ends",
  },
  start_after_end: {
    id: "PrecedenceTypeChoices.start_after_end",
    defaultMessage: "Successor starts after Predecessor ends",
  },
  start_after_start: {
    id: "PrecedenceTypeChoices.start_after_start",
    defaultMessage: "Successor starts after Predecessor starts",
  },
});

export const usePrecedenceTypeChoices = () => {
  const formatMessage = useFormatMessage();
  return useMemo(
    () => [
      {
        value: "start_after_end",
        label: formatMessage(precedenceTypeChoiceMessages.start_after_end),
      },
      {
        value: "start_after_start",
        label: formatMessage(precedenceTypeChoiceMessages.start_after_start),
      },
      {
        value: "end_after_end",
        label: formatMessage(precedenceTypeChoiceMessages.end_after_end),
      },
    ],
    [formatMessage],
  );
};
