import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTenantUrl } from "app/features/tenants/hooks";

interface TenantUrlLinkProps {
  url: string;
  children: ReactNode;
  [key: string]: unknown;
}

const TenantUrlLink = ({ url, children, ...linkProps }: TenantUrlLinkProps) => (
  <Link {...linkProps} to={useTenantUrl(url)}>
    {children}
  </Link>
);

export default TenantUrlLink;
