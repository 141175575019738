export interface Action {
  type: string;
  payload: unknown;
  status?: string;
}

// TODO
//  - isAction should not be necessary if we use Action everywhere
//  - we should use a type for the payload instead of unknown

export const isAction = (obj: Action) => obj?.type && obj?.payload;

export default {
  isAction,
};
