import AppTopNavbar from "app/core/components/AppTopNavbar";
import { useAppTopNavContext } from "app/core/providers/AppTopNavProvider";
import AppContent from "./AppContent";

//
// Application wrapper
//

const columnFlexWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  width: "100%",
  height: "100%",
};

const useAppContentWrapperStyle = () => {
  const { isTopNavHidden } = useAppTopNavContext();
  return { width: "100%", height: isTopNavHidden ? "100%" : "calc(100% - 48px)" };
};

const AppLayout = () => (
  <div style={columnFlexWrapperStyle}>
    <AppTopNavbar />
    <div style={useAppContentWrapperStyle()}>
      <AppContent />
    </div>
  </div>
);

export default AppLayout;
