import { fromJS } from "immutable";
import { isLoadingMap } from "app/utils/constants";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "app/features/calendars/actions";

const initialState = fromJS({
  all: {},
  detailed: {},
});

function handleLoadDetailedEventAsync(state, action) {
  let newState = state;
  if (action.status === "pending") {
    newState = newState.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const patternJS = action.payload.body;
    const patternMap = fromJS(patternJS);
    newState = newState.set("detailed", patternMap);
  }
  return newState;
}

//
// Handler for CLEAR_DETAILED_EVENT action
//
function handleClearDetailedEvent(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("detailed", fromJS({}));
  }
  return state;
}

//
// Resources reducer
//
function eventsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_DETAILED_EVENT_ASYNC]: handleLoadDetailedEventAsync,
    [actionTypes.CLEAR_DETAILED_EVENT]: handleClearDetailedEvent,
  });
}

export default eventsReducer;
