import { InternalPromise } from "app/utils/dal";
import ActionStatus from "./actionStatus";
import standardActions, { Action } from "./standardActions";

interface MightBePromise {
  then?: unknown;
}

function isPromise(val?: MightBePromise) {
  return typeof val?.then === "function";
}

// @ts-expect-error TODO fix this
function createPromiseMiddleware({ dispatch }) {
  // @ts-expect-error TODO fix this
  return function promiseMiddleware(next) {
    return (action?: Action) => {
      if (!action) {
        return next(action);
      }

      const { payload, status, type, ...otherActionProps } = action;

      if (!standardActions.isAction(action) || !isPromise(payload as MightBePromise)) {
        // not an action with a promise payload, ignore
        return next(action);
      }

      if (status) {
        // If the action already has a status, it means we already processed it, so ignore.
        return next(action);
      }

      if (!(payload instanceof InternalPromise)) {
        throw Error("The Promise used in the created action is not an instance of InternalPromise.");
      }

      const baseAction = { type, ...otherActionProps };

      // First we dispatch the action without the payload
      dispatch({ ...baseAction, status: ActionStatus.PENDING });

      // Then we chain the payload to dispatch a new action after it resolved
      payload.then(
        (result) => dispatch({ ...baseAction, payload: result, status: ActionStatus.DONE }),
        (error) => dispatch({ ...baseAction, payload: error, status: ActionStatus.ERROR }),
      );

      // dispatch() should always return the full dispatched action
      return action;
    };
  };
}

export default createPromiseMiddleware;
