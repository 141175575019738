import { Property } from "csstype";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const panelBodyStyle = { textAlign: "center" as Property.TextAlign };

const NotFound = ({
  actionLink = null,
  actionLabel = <FormattedMessage id="NotFound.actionLabel" defaultMessage="Go back" />,
  message = <FormattedMessage id="NotFound.message" defaultMessage="Not found" />,
}) => {
  const actionPart = actionLink ? (
    <Link to={actionLink} className="btn">
      <span className="icon fa fa-arrow-left" />
      {actionLabel}
    </Link>
  ) : null;

  return (
    <div className="content-full">
      <div className="panel panel-widget not-found">
        <div className="panel-body" style={panelBodyStyle}>
          <div>
            <span className="icon fa fa-cog fa-2x" />
          </div>

          {message}
          {actionPart}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
