import { getEmptyPromise } from "app/utils/dal";
import RecurrenceDal from "./dal";

export const actionTypes = {
  LOAD_DETAILED_RECURRENCE: "recurrences/LOAD_DETAILED_RECURRENCE",
  CLEAR_DETAILED_RECURRENCE: "recurrences/CLEAR_DETAILED_RECURRENCE",
  CREATE_RECURRENCE: "recurrences/CREATE_RECURRENCE",
  UPDATE_RECURRENCE: "recurrences/UPDATE_RECURRENCE",
  DELETE_RECURRENCE: "recurrences/DELETE_RECURRENCE",

  LOAD_DETAILED_RECURRENCERULE: "recurrences/LOAD_DETAILED_RECURRENCERULE",
  CLEAR_DETAILED_RECURRENCERULE: "recurrences/CLEAR_DETAILED_RECURRENCERULE",
  CREATE_RECURRENCERULE: "recurrences/CREATE_RECURRENCERULE",
  UPDATE_RECURRENCERULE: "recurrences/UPDATE_RECURRENCERULE",
  DELETE_RECURRENCERULE: "recurrences/DELETE_RECURRENCERULE",
  PREVIEW_RECURRENCERULE: "recurrences/PREVIEW_RECURRENCERULE",
};

const actionCreators = {
  createRecurrence(recurrence) {
    const dal = new RecurrenceDal();
    const actionPromise = dal.createRecurrence(recurrence);
    return {
      type: actionTypes.CREATE_RECURRENCE,
      payload: actionPromise,
    };
  },

  deleteRecurrence(recurrencePk) {
    const dal = new RecurrenceDal();
    const actionPromise = dal.deleteRecurrence(recurrencePk);
    return {
      type: actionTypes.DELETE_RECURRENCE,
      payload: actionPromise,
    };
  },

  loadDetailedRecurrenceRule(recurrenceRulePk) {
    const dal = new RecurrenceDal();
    const actionPromise = dal.getDetailedRecurrenceRule(recurrenceRulePk);
    return {
      type: actionTypes.LOAD_DETAILED_RECURRENCERULE,
      payload: actionPromise,
    };
  },

  clearDetailedRecurrenceRule() {
    const actionPromise = getEmptyPromise();

    return {
      type: actionTypes.CLEAR_DETAILED_RECURRENCERULE,
      payload: actionPromise,
    };
  },

  createRecurrenceRule(recurrenceRule) {
    const dal = new RecurrenceDal();
    const actionPromise = dal.createRecurrenceRule(recurrenceRule);
    return {
      type: actionTypes.CREATE_RECURRENCERULE,
      payload: actionPromise,
    };
  },

  updateRecurrenceRule(recurrenceRule) {
    const dal = new RecurrenceDal();
    const actionPromise = dal.putRecurrenceRule(recurrenceRule);
    return {
      type: actionTypes.UPDATE_RECURRENCERULE,
      payload: actionPromise,
    };
  },

  deleteRecurrenceRule(recurrenceRulePk) {
    const dal = new RecurrenceDal();
    const actionPromise = dal.deleteRecurrenceRule(recurrenceRulePk);
    return {
      type: actionTypes.DELETE_RECURRENCERULE,
      payload: actionPromise,
    };
  },

  previewRecurrenceRule(recurrenceRule, start, end) {
    const dal = new RecurrenceDal();
    const actionPromise = dal.previewRecurrenceRule(recurrenceRule, start, end);
    return {
      type: actionTypes.PREVIEW_RECURRENCERULE,
      payload: actionPromise,
    };
  },
};

export default actionCreators;
