import InternalDal from "app/utils/InternalDal";

class ResourcesDal {
  constructor(apiUrl) {
    this.dal = new InternalDal(apiUrl);
    this.resourcesUrl = `${this.dal.baseTenantUrl}/resources`;
    this.breedsUrl = `${this.dal.baseTenantUrl}/resource-breeds`;
  }

  getSimpleResources() {
    const url = `${this.resourcesUrl}/`;

    return this.dal.getter(url, "getSimpleResources");
  }

  getDetailedResource(resourcePk) {
    const url = `${this.resourcesUrl}/detailed/${resourcePk}/`;

    return this.dal.getter(url, "getDetailedResource");
  }

  getResourceWorkTemplates(resourcePk) {
    const url = `${this.resourcesUrl}/${resourcePk}/work_templates`;

    return this.dal.getter(url, "getResourceWorkTemplates");
  }

  cloneResource(pk) {
    const url = `${this.resourcesUrl}/detailed/${pk}/clone/`;

    return this.dal.poster(url, "", "cloneResource");
  }

  createResource(resource) {
    const url = `${this.resourcesUrl}/detailed/`;

    return this.dal.poster(url, resource, "createResource");
  }

  putResource(resource) {
    const url = `${this.resourcesUrl}/detailed/${resource.pk}/`;

    return this.dal.putter(url, resource, "putResource");
  }

  deleteResource(resourcePk) {
    const url = `${this.resourcesUrl}/${resourcePk}/`;

    return this.dal.deleter(url, "deleteResource");
  }

  patchResourceSkills(resourcePk, skillPks) {
    const url = `${this.resourcesUrl}/detailed/${resourcePk}/`;
    const data = { skills: skillPks };

    return this.dal.patcher(url, data, "patchResourceSkills");
  }

  //
  // BREEDS
  //

  getSimpleBreeds() {
    const url = `${this.breedsUrl}/`;

    return this.dal.getter(url, "getSimpleBreeds");
  }

  getDetailedBreed(breedPk) {
    const url = `${this.breedsUrl}/${breedPk}/`;

    return this.dal.getter(url, "getDetailedBreed");
  }

  createBreed(breed) {
    const url = `${this.breedsUrl}/`;

    return this.dal.poster(url, breed, "createBreed");
  }

  putBreed(breed) {
    const url = `${this.breedsUrl}/${breed.pk}/`;

    return this.dal.putter(url, breed, "putBreed");
  }

  patchBreedResources(breedPk, resourcePks) {
    const url = `${this.breedsUrl}/${breedPk}/`;
    const data = { resources: resourcePks };

    return this.dal.patcher(url, data, "patchBreedResources");
  }

  deleteBreed(breedPk) {
    const url = `${this.breedsUrl}/${breedPk}/`;

    return this.dal.deleter(url, "deleteBreed");
  }
}

export default ResourcesDal;
