import { range } from "lodash";
import moment from "moment";
import { defineMessages } from "react-intl";

const frequencyChoiceMessages = defineMessages({
  daily: {
    id: "frequencyChoices.daily",
    defaultMessage: "Daily",
  },
  weekly: {
    id: "frequencyChoices.weekly",
    defaultMessage: "Weekly",
  },
  monthly: {
    id: "frequencyChoices.monthly",
    defaultMessage: "Monthly",
  },
  yearly: {
    id: "frequencyChoices.yearly",
    defaultMessage: "Yearly",
  },
});

const frequencyChoices = (formatMessage) => [
  { value: 3, label: formatMessage(frequencyChoiceMessages.daily) },
  { value: 2, label: formatMessage(frequencyChoiceMessages.weekly) },
  { value: 1, label: formatMessage(frequencyChoiceMessages.monthly) },
  { value: 0, label: formatMessage(frequencyChoiceMessages.yearly) },
];

const yearDays = range(1, 366 + 1);
const monthDays = range(1, 31 + 1);
const weekNumbers = range(1, 53 + 1);

// Monday till Sunday
const weekDays = range(1, 7 + 1).map((day) => moment().day(day).format("dddd"));

// January till December
const monthNames = range(0, 12).map((month) => moment().month(month).format("MMMM"));

const setPositionChoices = [
  { value: -2, label: "Second last" },
  { value: -1, label: "Last" },
  { value: 1, label: "First" },
  { value: 2, label: "Second" },
  { value: 3, label: "Third" },
  { value: 4, label: "Fourth" },
];

export default {
  setPositionChoices,
  frequencyChoices,
  monthNames,
  monthDays,
  weekNumbers,
  weekDays,
  yearDays,
};
