import { createContext, useCallback, useContext, useEffect, useRef } from "react";
import useToggleState from "app/common/hooks/useToggleState";
import { isProduction } from "app/process";

/**
 * Java-like hashCode function for strings, taken from:
 * http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
 */
const stringToHash = (str) => {
  const len = str.length;
  let hash = 0;
  if (len === 0) return hash;
  let i;
  for (i = 0; i < len; i += 1) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const shouldCheckForUpdates = isProduction;
const checkIntervalInMinutes = 1;
const checkIntervalInMs = checkIntervalInMinutes * 60 * 1000;

const useAppUpdater = () => {
  const { on: startUpdateAvailable, value: updateAvailable } = useToggleState();
  const { on: startUpdateDismissed, off: stopUpdateDismissed, value: updateDismissed } = useToggleState();

  const refIndexHtmlHash = useRef(null);
  const refPollingIntervalId = useRef(null);
  const refSkipCheck = useRef(false);

  const shouldSkipCheck = updateAvailable || updateDismissed;

  useEffect(() => {
    refSkipCheck.current = shouldSkipCheck;
  }, [shouldSkipCheck]);

  const handleAppUpdate = useCallback(() => {
    if (refSkipCheck.current) return;

    const prevIndexHtmlHash = refIndexHtmlHash.current;
    const isNotInitialized = !prevIndexHtmlHash;

    console.log(isNotInitialized ? "[YouPlan] initialising app updater..." : "[YouPlan] checking for app updates");

    return fetch("/index.html")
      .then((response) => {
        if (response.status !== 200) {
          console.error("[YouPlan] something went wrong while checking for app updates...");
          throw new Error("Error in retrieving index.html");
        }
        return response.text();
      })
      .then((indexHtml) => {
        const indexHtmlHash = stringToHash(indexHtml);
        refIndexHtmlHash.current = indexHtmlHash;

        if (isNotInitialized) {
          console.log("[YouPlan] initialised app updater.");
        } else if (prevIndexHtmlHash === indexHtmlHash) {
          console.log("[YouPlan] no updates available.");
        } else {
          console.log("[YouPlan] app update available!");
          startUpdateAvailable();
        }
        // Suppresses runaway promise warnings
        return null;
      })
      .catch(() => {
        /* do nothing */
      });
  }, [startUpdateAvailable]);

  useEffect(() => {
    if (shouldCheckForUpdates) {
      // Check now and set polling interval.
      handleAppUpdate().then(() => {
        refPollingIntervalId.current = setInterval(handleAppUpdate, checkIntervalInMs);
      });
      const clearPolling = () => clearInterval(refPollingIntervalId.current);
      return clearPolling;
    }
  }, [handleAppUpdate]);

  const handleTriggerUpdate = useCallback(() => {
    stopUpdateDismissed();
    startUpdateAvailable();
  }, [startUpdateAvailable, stopUpdateDismissed]);

  return {
    isUpdateAvailable: updateAvailable,
    isUpdateDismissed: updateDismissed,
    onDismissUpdate: startUpdateDismissed,
    onTriggerUpdate: handleTriggerUpdate,
  };
};

const AppUpdateContext = createContext({});
const AppUpdateProvider = ({ children }) => (
  <AppUpdateContext.Provider value={useAppUpdater()}>{children}</AppUpdateContext.Provider>
);

export const useAppUpdateContext = () => {
  const context = useContext(AppUpdateContext);
  if (context === undefined) {
    throw new Error("useAppUpdateContext must be used within a AppUpdateProvider");
  }
  return context;
};

export default AppUpdateProvider;
