import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from "@elastic/eui";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import HelpIconTip from "app/common/components/HelpIconTip";
import { CustomIconWithLabel, Icons } from "app/utils/constants";
import { SnapshotScopeManager } from "app/features/snapshots/components/SnapshotScopeManager";
import { manageSnapshotScopesUrl } from "app/features/snapshots/routing";
import TenantUrlLink from "app/features/tenants/components/TenantUrlLink";

const ManageSnapshotScopesLink = () => (
  <TenantUrlLink url={manageSnapshotScopesUrl}>
    <CustomIconWithLabel icon={Icons.manage} label={<FormattedMessage id="SnapshotScopeManager.manageScopes" />} />
  </TenantUrlLink>
);

const SnapshotScopeModal = ({
  onCloseModal,
  // minDate,
  // maxDate,
  // startDate,
  // endDate,
}) => {
  return (
    <EuiOverlayMask>
      <EuiModal onClose={onCloseModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <>
              <FormattedMessage id="SnapshotScopeManager.changeScope" />
              &nbsp;
              <HelpIconTip content={<FormattedMessage id="SnapshotScopeManager.changeScope.help" />} />
            </>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <ManageSnapshotScopesLink />
          <br />
          <br />
          <SnapshotScopeManager canEdit={false} />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={onCloseModal}>
            <FormattedMessage id="actions.Close" />
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  );
};

export default memo(SnapshotScopeModal);
