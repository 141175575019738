import { fromJS } from "immutable";
import { ItemTypes, emptyMap, isLoadingMap, notLoadedMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import { ResourceBreedRecord, ResourceDetailedRecord, ResourceSimpleRecord } from "app/utils/records";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";

const initialState = fromJS({
  resources: {
    all: notLoadedMap,
    filter: {},
  },
  detailed: {
    resource: ResourceDetailedRecord(),
    skills: {},
    schedule: {
      calendarLinks: {},
      previews: {},
    },
  },

  breeds: {
    all: notLoadedMap,
    detailed: ResourceBreedRecord(),
  },
});

//
// Handler for LOAD_SIMPLE_RESOURCES_ASYNC action
//
function handleLoadSimpleResourcesAsync(oldState, action) {
  let state = oldState;
  if (action.status === "pending") {
    state = state.setIn(["resources", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const resourcesJS = action.payload.body.results;
    const resourcesMap = arrayToPkMap(resourcesJS, ItemTypes.resource).map(ResourceSimpleRecord);
    state = state.setIn(["resources", "all"], resourcesMap);
  }
  return state;
}

function handleClearSimpleResources(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.setIn(["resources", "all"], notLoadedMap);
  }

  return state;
}

//
// Handler for LOAD_DETAILED_RESOURCE_ASYNC action
//
function handleLoadDetailedResourceAsync(oldState, action) {
  let state = oldState;
  if (action.status === "pending") {
    state = state.setIn(["detailed", "resource"], isLoadingMap);
  }
  if (action.status === "done") {
    const resourceJS = action.payload.body;
    const resourceRecord = ResourceDetailedRecord(fromJS(resourceJS));
    state = state.setIn(["detailed", "resource"], resourceRecord);
  }
  return state;
}

//
// Handler for CLEAR_DETAILED_RESOURCE action
//
function handleClearDetailedResource(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.setIn(["detailed", "resource"], emptyMap);
  }

  return state;
}

//
// Handler for LOAD_COLORSLOTS_RESOURCE_PREVIEW_ASYNC action
//
function handleColorSlotsResourcePreviewAsync(oldState, action) {
  let state = oldState;
  if (action.status === "pending") {
    state = state.setIn(["detailed", "schedule", "previews"], isLoadingMap);
  }
  if (action.status === "done") {
    const previewsJS = action.payload.body.results;
    const previewsMapJS = {};
    previewsJS.forEach((r) => {
      previewsMapJS[r.rank] = r;
    });
    state = state.setIn(["detailed", "schedule", "previews"], fromJS(previewsMapJS));
  }

  return state;
}

//
// BREEDS
//

//
// Handler for LOAD_SIMPLE_BREEDS action
//
function handleLoadSimpleBreeds(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["breeds", "all"], isLoadingMap);
  }
  if (action.status === "done") {
    const breedsJS = action.payload.body.results;
    const breedRecordsMap = arrayToPkMap(breedsJS, "breed").map(ResourceBreedRecord);
    state = state.setIn(["breeds", "all"], breedRecordsMap);
  }
  return state;
}

//
// Handler for CLEAR_SIMPLE_BREEDS action
//
function handleClearSimpleBreeds(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.setIn(["breeds", "all"], notLoadedMap);
  }

  return state;
}

//
// Handler for LOAD_DETAILED_BREED action
//
function handleLoadDetailedBreed(prevState, action) {
  let state = prevState;

  if (action.status === "pending") {
    state = state.setIn(["breeds", "detailed"], isLoadingMap);
  }

  if (action.status === "done") {
    const breedJS = action.payload.body;

    state = state.setIn(["breeds", "detailed"], ResourceBreedRecord(fromJS(breedJS)));
  }

  return state;
}

//
// Handler for CLEAR_DETAILED_BREED action
//
function handleClearDetailedBreed(prevState, action) {
  let state = prevState;

  if (action.status === "done") {
    state = state.setIn(["breeds", "detailed"], notLoadedMap);
  }

  return state;
}

/***
 * Resources reducer
 * @param state
 * @param action
 */
export default function (state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_RESOURCES_ASYNC]: handleLoadSimpleResourcesAsync,
    [actionTypes.LOAD_DETAILED_RESOURCE_ASYNC]: handleLoadDetailedResourceAsync,
    [actionTypes.CLEAR_SIMPLE_RESOURCES]: handleClearSimpleResources,
    [actionTypes.CLEAR_DETAILED_RESOURCE]: handleClearDetailedResource,

    [actionTypes.LOAD_COLORSLOTS_RESOURCE_PREVIEW_ASYNC]: handleColorSlotsResourcePreviewAsync,

    // BREEDS
    [actionTypes.LOAD_SIMPLE_BREEDS]: handleLoadSimpleBreeds,
    [actionTypes.CLEAR_SIMPLE_BREEDS]: handleClearSimpleBreeds,
    [actionTypes.LOAD_DETAILED_BREED]: handleLoadDetailedBreed,
    [actionTypes.CLEAR_DETAILED_BREED]: handleClearDetailedBreed,
  });
}
