import { Map } from "immutable";
import { useCallback, useRef } from "react";
import { isMapNotReady } from "app/utils/constants";

// TODO should probably be typed with expected cleanedData type
type FormCallback = (args0: unknown) => void;

interface FormCallbackArgs {
  cleanedData: Record<string, unknown>;
}

export const useFormRef = () => {
  const formRef = useRef();
  const submitForm = useCallback(() => {
    // @ts-expect-error TODO fix this
    formRef.current?.handleSubmit();
  }, []);
  return [formRef, submitForm];
};

export const emptyFormValues = Map<string, unknown>();

export const useFormValues = (values: Map<string, unknown>) => (isMapNotReady(values) ? null : values);

// TODO if schema does not exist we should get rawData!
export const useFormCallback = (callback: FormCallback) => {
  return useCallback(({ cleanedData }: FormCallbackArgs) => callback(cleanedData), [callback]);
};
