export const manageResourcesUrl = "manage/resources";
export const manageResourceBreedsUrl = "manage/resources/breeds";

export const newResourceUrl = "manage/resources/new";

export const importResourcesUrl = "manage/resources/import";
export const resourcesSkillsUrl = "manage/resources/skills";

export const getResourceUrl = (pk: number, tab = null, edit = false) => {
  let url = `${manageResourcesUrl}/${pk}`;
  if (edit) url += "/edit";
  if (tab) url += `/${tab}`;
  return url;
};
export const getResourceBreedUrl = (breedPk: number) => `${manageResourceBreedsUrl}/${breedPk}`;

export const newResourceBreedUrl = `${manageResourceBreedsUrl}/new`;
