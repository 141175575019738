import { EuiIconTip, ToolTipPositions } from "@elastic/eui";
import { ReactNode } from "react";

interface HelpIconTipProps {
  content: ReactNode;
  position?: ToolTipPositions;
}

const HelpIconTip = ({ content, position = "right" }: HelpIconTipProps) => (
  <EuiIconTip content={content} position={position} />
);

export default HelpIconTip;
