import { fromJS } from "immutable";
import { isLoadingMap, notLoadedMap } from "app/utils/constants";
import { arrayToPkMap } from "app/utils/immutableUtils";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { actionTypes } from "./actions";

const initialState = fromJS({
  all: notLoadedMap,
  detailed: notLoadedMap,
});

function handleLoadSimpleSkillsAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("all", isLoadingMap);
  }
  if (action.status === "done") {
    const skillsJS = action.payload.body.results;
    const skillsMap = arrayToPkMap(skillsJS, "skill");
    state = state.set("all", skillsMap);
  }
  return state;
}

function handleClearSimpleSkills(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("all", notLoadedMap);
  }
  return state;
}

function handleLoadDetailedSkillAsync(prevState, action) {
  let state = prevState;
  if (action.status === "pending") {
    state = state.set("detailed", isLoadingMap);
  }
  if (action.status === "done") {
    const skillJS = action.payload.body;
    const skillMap = fromJS(skillJS);
    state = state.set("detailed", skillMap);
  }
  return state;
}

function handleClearDetailedSkill(prevState, action) {
  let state = prevState;
  if (action.status === "done") {
    state = state.set("detailed", notLoadedMap);
  }
  return state;
}

function skillsReducer(state = initialState, action) {
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_SIMPLE_SKILLS_ASYNC]: handleLoadSimpleSkillsAsync,
    [actionTypes.LOAD_DETAILED_SKILL_ASYNC]: handleLoadDetailedSkillAsync,
    [actionTypes.CLEAR_SIMPLE_SKILLS]: handleClearSimpleSkills,
    [actionTypes.CLEAR_DETAILED_SKILL]: handleClearDetailedSkill,
  });
}

export default skillsReducer;
