import { is } from "immutable";
import { useEffect, useMemo, useRef } from "react";
import moment, { Moment, MomentOrString } from "app/utils/momentLocalized";

// TODO make sure there's only one clear way of getting a pk from an item

// @ts-expect-error TODO fix type
export const getAssignmentWorkPk = (a) => a.get("work_pk") ?? a.getIn(["work", "pk"]) ?? a.get("work");
// @ts-expect-error TODO fix type
export const getResourceBreedPk = (r) => r.get("breed_pk") ?? r.getIn(["breed", "pk"]) ?? r.get("breed");

export const getDateRangeKey = (start: MomentOrString, end: MomentOrString) =>
  `${moment(start).format()}-${moment(end).format()}`;

type MomentCache = Record<string, Moment>;

export const momentWithCache = (d: string, cache: MomentCache) => {
  let m = cache[d];
  if (!m) {
    m = moment(d);
    cache[d] = m;
  }
  return m;
};

// @ts-expect-error TODO fix type
export const setItemStartEndMoments = (item, momentCache: MomentCache) => {
  item = item.toMap();

  const setStart = !item.get("startMoment");
  const setEnd = !item.get("endMoment");
  if (!(setStart || setEnd)) return item;

  if (setStart) {
    const start = item.get("start");
    const startMoment = momentCache ? momentWithCache(start, momentCache) : moment(start);
    item = item.set("startMoment", startMoment);
  }
  if (setEnd) {
    const end = item.get("end");
    const endMoment = momentCache ? momentWithCache(end, momentCache) : moment(end);
    item = item.set("endMoment", endMoment);
  }

  return item;
};

// @ts-expect-error TODO fix type
export const useImmutableCached = (immutable) => {
  const ref = useRef(immutable);
  const prevImmutable = ref.current;
  const changed = !is(prevImmutable, immutable);

  useEffect(() => {
    if (changed) {
      ref.current = immutable;
    }
  }, [immutable, changed]);

  return changed ? immutable : prevImmutable;
};

export const useMomentCached = (m: MomentOrString) => {
  const ref = useRef<MomentOrString | null>(null);
  const prevMoment = ref.current;
  const nextMoment = useMemo(() => moment(m), [m]);
  const changed = !nextMoment.isSame(prevMoment);

  useEffect(() => {
    // Only update refs on actual changes!
    if (changed) {
      ref.current = nextMoment;
    }
  }, [changed, nextMoment]);

  return changed ? nextMoment : prevMoment;
};
