import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAppTenantContext } from "app/core/providers/AppTenantDetectionProvider";
import useLoadingState from "app/common/hooks/useLoadingState";
import useToggleState from "app/common/hooks/useToggleState";
import InternalDal from "app/utils/InternalDal";
import { useAppActions } from "app/actions";
import { useTenantNav } from "app/features/tenants/hooks";

export const getScopeRecurrenceUrl = (scopePk, recurrencePk) =>
  `snapshots/scopes/${scopePk}/recurrence/${recurrencePk}`;

const useSnapshotScopes = () => {
  const { isLoading, loadUntilResolvedOrRejected, loadUntilRejected } = useLoadingState();

  const [scopes, setScopes] = useState(null);

  // API actions
  const actions = useAppActions();
  const loadSnapshotScopes = useCallback(() => {
    const dal = new InternalDal();
    const url = dal.makeTenantAwareApiUrl("snapshot-scopes");
    const promise = dal.getter(url, "getSnapshotScopes");
    const action = actions.api.request(promise, "GET_SNAPSHOT_SCOPES");
    return action;
  }, [actions]);

  const createSnapshotScope = useCallback(
    ({ start, end, name = null }) => {
      const data = {
        start,
        end,
        name,
      };
      const dal = new InternalDal();
      const url = dal.makeTenantAwareApiUrl("snapshot-scopes");
      const promise = dal.poster(url, data, "createSnapshotScope");
      const action = actions.api.request(promise, "CREATE_SNAPSHOT_SCOPE");
      return action;
    },
    [actions],
  );

  const patchSnapshotScopeRecurrence = useCallback(
    (pk, recurrencePk) => {
      const dal = new InternalDal();
      const url = dal.makeTenantAwareApiUrl(`snapshot-scopes/${pk}`);
      const promise = dal.patcher(url, { recurrence_pk: recurrencePk }, "patchSnapshotScope");
      const action = actions.api.request(promise, "PATCH_SNAPSHOT_SCOPE");
      return action;
    },
    [actions],
  );

  const deleteSnapshotScope = useCallback(
    (pk) => {
      const dal = new InternalDal();
      const url = dal.makeTenantAwareApiUrl(`snapshot-scopes/${pk}`);
      const promise = dal.deleter(url, "deleteSnapshotScope");
      const action = actions.api.request(promise, "DELETE_SNAPSHOT_SCOPE");
      return action;
    },
    [actions],
  );

  // Handlers
  const handleLoad = useCallback(() => {
    return loadUntilResolvedOrRejected(
      loadSnapshotScopes().payload.then((response) => {
        setScopes(response.body.results);
      }),
    );
  }, [loadSnapshotScopes, loadUntilResolvedOrRejected]);
  const handleCreate = useCallback(
    (snapshotScopeData) => {
      return loadUntilResolvedOrRejected(createSnapshotScope(snapshotScopeData).payload.then(handleLoad));
    },
    [createSnapshotScope, handleLoad, loadUntilResolvedOrRejected],
  );
  const handleDelete = useCallback(
    (pk) => {
      return loadUntilResolvedOrRejected(deleteSnapshotScope(pk).payload.then(handleLoad));
    },
    [deleteSnapshotScope, handleLoad, loadUntilResolvedOrRejected],
  );

  // TODO we should split the patching and navigating to the recurrence
  const tenantNav = useTenantNav();
  const handleAddRecurrence = useCallback(
    (pk) => {
      return loadUntilRejected(
        actions.recurrences.createRecurrence({}).payload.then((response) => {
          const createdRecurrencePk = response.body.pk;
          const createdRecurrenceUrl = getScopeRecurrenceUrl(pk, createdRecurrencePk);

          return patchSnapshotScopeRecurrence(pk, createdRecurrencePk)
            .payload.then(handleLoad)
            .then(() => tenantNav(createdRecurrenceUrl));
        }),
      );
    },
    [actions, handleLoad, patchSnapshotScopeRecurrence, loadUntilRejected, tenantNav],
  );

  const handleDeleteRecurrence = useCallback(
    (scope) => {
      const recurrencePk = scope.recurrence?.pk;

      return loadUntilRejected(actions.recurrences.deleteRecurrence(recurrencePk).payload.then(handleLoad));
    },
    [actions, handleLoad, loadUntilRejected],
  );

  // Initial loading of scopes
  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return {
    scopes,
    isLoading,
    handleLoad,

    handleCreate,
    handleDelete,

    handleAddRecurrence,
    handleDeleteRecurrence,
  };
};

const useTenantUserSnapshotScope = () => {
  const { isTenantLoaded } = useAppTenantContext();

  const { isLoading, loadUntilResolvedOrRejected } = useLoadingState();

  const [isInitialized, setIsInitialized] = useState(false);
  const [tenantUserScope, setTenantUserScope] = useState(null);

  // API actions
  const actions = useAppActions();
  const loadTenantUserSnapshotScopes = useCallback(() => {
    const dal = new InternalDal();
    const url = dal.makeTenantAwareApiUrl("tenant-user-snapshot-scopes");
    const promise = dal.getter(url, "getTenantUserSnapshotScopes");
    const action = actions.api.request(promise, "GET_TENANT_USER_SNAPSHOT_SCOPES");
    return action;
  }, [actions]);

  const activateTenantUserSnapshotScope = useCallback(
    (snapshotScopePk) => {
      const data = { snapshot_scope: snapshotScopePk };
      const dal = new InternalDal();
      const url = dal.makeTenantAwareApiUrl("tenant-user-snapshot-scopes/activate");
      const promise = dal.poster(url, data, "activateTenantUserSnapshotScope");
      const action = actions.api.request(promise, "ACTIVATE_TENANT_USER_SNAPSHOT_SCOPE");
      return action;
    },
    [actions],
  );

  // Handlers
  const handleLoad = useCallback(() => {
    return loadUntilResolvedOrRejected(
      loadTenantUserSnapshotScopes().payload.then((response) => {
        const { results } = response.body;
        setTenantUserScope(results.length ? results[0] : null);
        setIsInitialized(true);
      }),
    );
  }, [loadTenantUserSnapshotScopes, loadUntilResolvedOrRejected]);
  const handleActivate = useCallback(
    (snapshotScopePk) => {
      return loadUntilResolvedOrRejected(activateTenantUserSnapshotScope(snapshotScopePk).payload.then(handleLoad));
    },
    [activateTenantUserSnapshotScope, handleLoad, loadUntilResolvedOrRejected],
  );

  // Initial loading of scopes
  useEffect(() => {
    // TODO we actually want to rely on correct TenantUser initialized
    if (isTenantLoaded) {
      handleLoad();
    }
  }, [isTenantLoaded, handleLoad]);

  return {
    tenantUserScope,
    isLoading,
    isInitialized,
    handleActivate,
  };
};

const SnapshotScopeContext = createContext({});

const SnapshotScopeProvider = ({ children }) => {
  const {
    scopes,
    isLoading: isLoadingScopes,
    handleCreate: handleCreateScope,
    handleDelete: handleDeleteScope,
    handleAddRecurrence: handleAddScopeRecurrence,
    handleDeleteRecurrence: handleDeleteScopeRecurrence,
  } = useSnapshotScopes();

  const {
    tenantUserScope,
    isInitialized: activeScopeInitialized,
    isLoading: isLoadingActiveScope,
    handleActivate: handleActivateScope,
  } = useTenantUserSnapshotScope();

  const {
    value: isScopeModalOpen,
    on: openScopeModal,
    toggle: toggleScopeModal,
    off: closeScopeModal,
  } = useToggleState(false);

  const activeScopePk = tenantUserScope ? tenantUserScope.snapshot_scope : null;
  const activeScope = scopes?.find(({ pk }) => pk === activeScopePk);

  const contextValue = {
    scopes,

    activeScope,
    activeScopePk,
    activeScopeInitialized,

    handleActivateScope,
    handleCreateScope,
    handleDeleteScope,

    handleAddScopeRecurrence,
    handleDeleteScopeRecurrence,

    isLoadingScopes,
    isLoadingActiveScope,

    isScopeModalOpen,
    openScopeModal,
    toggleScopeModal,
    closeScopeModal,
  };

  return <SnapshotScopeContext.Provider value={contextValue}>{children}</SnapshotScopeContext.Provider>;
};

export const useSnapshotScopeContext = () => useContext(SnapshotScopeContext);

export default SnapshotScopeProvider;
