import { fromJS } from "immutable";
import { emptyMap, isLoadingMap } from "app/utils/constants";
import moment from "app/utils/momentLocalized";
import reduceWithHandlers from "app/utils/redux/reduceWithHandlers";
import { State } from "app/utils/redux/reducerHandlers";
import { Action } from "app/utils/redux/standardActions";
import { actionTypes, actionTypes as userActionTypes } from "./actions";

const initialState = fromJS({
  userProfile: {},
  user: {},
  userNotAuthorised: false,
  userIsAllowedToDebug: false,
  userIsInDebugMode: false,
  userIsLoggingOut: false,

  firebaseUser: null,
});

//
// Handlers
//
function handleUserNotAuthorisedError(state: State, action: Action) {
  const { status, payload, type } = action;

  if (status === "error") {
    const { errorInfo } = payload as { errorInfo: { status: number } };

    // If we are trying to get the user and a not authenticated status is returned we just redirect
    // to the login-page, the error recording is not necessary!
    const userNotAuthorisedError =
      type !== userActionTypes.GET_USER && errorInfo && (errorInfo.status === 403 || errorInfo.status === 401);

    state = state.set("userNotAuthorised", userNotAuthorisedError);
  }

  return state;
}

function handleLoadUserProfile(state: State, action: Action) {
  if (action.status === "pending") {
    state = state.set("userProfile", isLoadingMap);
  }

  if (action.status === "done") {
    // @ts-expect-error TODO fix types
    const userProfileJS = action.payload.body;
    const {
      pk,
      display_name,
      locale,
      spectator,
      planboard_all_day,
      planboard_interval,
      planboard_panels,
      planboard_current_date,
      planboard_empty_resources,
      planboard_empty_work,
      planboard_empty_work_if_fully_planned,
      planboard_color_available,
      planboard_color_unavailable,
      planboard_color_rather_not,
      planboard_display_text_assignments_resources,
      planboard_display_text_assignments_works,
      planboard_group_assignments,
      planboard_projects_collapsed,
      planning_export_labels_assignments,
      planning_export_labels_resources,
      planning_auto_load_changes,
    } = userProfileJS;

    // Note this is actually a pinned date
    // TODO in its current form unused and not very useful (could be handy to pin multiple dates later on)
    const m = moment(planboard_current_date);
    const planBoardCurrentDate = m.isValid() ? m : null;

    const userProfile = fromJS({
      pk,
      isLoading: false,
      userProfile: userProfileJS,
      displayName: display_name,
      locale,
      isSpectator: spectator,
      planBoardStartTime: moment(userProfileJS.planboard_hours_start, "hh:mm:ss"),
      planBoardEndTime: moment(userProfileJS.planboard_hours_end, "hh:mm:ss"),
      planBoardAllDay: planboard_all_day,
      planBoardColorAvailable: planboard_color_available,
      planBoardColorUnavailable: planboard_color_unavailable,
      planBoardColorRatherNot: planboard_color_rather_not,
      planBoardCurrentDate,
      planBoardDisplayTextAssignmentsResources: planboard_display_text_assignments_resources,
      planBoardDisplayTextAssignmentsWorks: planboard_display_text_assignments_works,
      planBoardGroupAssignments: planboard_group_assignments,
      planBoardIntervalType: planboard_interval,
      planBoardPanelOption: planboard_panels,
      planBoardProjectsDefaultCollapsed: planboard_projects_collapsed,
      planBoardShowEmptyTimelinesResources: planboard_empty_resources,
      planBoardShowEmptyTimelinesWorks: planboard_empty_work,
      planBoardShowEmptyTimelinesWorksIfFullyPlanned: planboard_empty_work_if_fully_planned,
      planningAutoLoadChanges: planning_auto_load_changes,
      planningExportLabelsAssignments: planning_export_labels_assignments,
      planningExportLabelsResources: planning_export_labels_resources,
    });
    const userIsInDebugMode = state.get("userIsAllowedToDebug") && userProfileJS.debug_mode;

    state = state.set("userProfile", userProfile);
    state = state.set("userIsInDebugMode", userIsInDebugMode);
  }

  return state;
}

function handleUpdateUserProfile(state: State, action: Action) {
  if (action.status === "done") {
    state = handleLoadUserProfile(state, action);
  }

  return state;
}

function handleGetUser(state: State, action: Action) {
  if (action.status === "done") {
    // @ts-expect-error TODO fix types
    const user = fromJS(action.payload.body);
    state = state.set("user", user);
    state = state.set("userIsAllowedToDebug", user.get("is_staff"));
  }

  return state;
}

function handleClearUser(state: State, _action: Action) {
  return state.set("user", emptyMap).set("userProfile", emptyMap);
}

function handleClearUserNotAuthorised(state: State, _action: Action) {
  return state.set("userNotAuthorised", false);
}

function handleSetFirebaseUser(state: State, action: Action) {
  return state.set("firebaseUser", action.payload);
}

function handleClearFirebaseUser(state: State, _action: Action) {
  return state.set("firebaseUser", null);
}

function handleSetUserLoggingOut(state: State, action: Action) {
  const isLoggingOut = action.status === "pending";
  return state.set("userIsLoggingOut", isLoggingOut);
}

//
// Reducer
//

function configReducer(state = initialState, action: Action) {
  state = handleUserNotAuthorisedError(state, action);
  return reduceWithHandlers(state, action, {
    [actionTypes.LOAD_USER_PROFILE]: handleLoadUserProfile,
    [actionTypes.UPDATE_USER_PROFILE]: handleUpdateUserProfile,

    [actionTypes.GET_USER]: handleGetUser,
    [actionTypes.CLEAR_USER]: handleClearUser,
    [actionTypes.CLEAR_USER_NOT_AUTHORISED]: handleClearUserNotAuthorised,

    [actionTypes.SET_FIREBASE_USER]: handleSetFirebaseUser,
    [actionTypes.CLEAR_FIREBASE_USER]: handleClearFirebaseUser,

    [actionTypes.LOGOUT]: handleSetUserLoggingOut,
  });
}

export default configReducer;
