export const manageStoragesUrl = "manage/storages";
export const manageStorageBreedsUrl = "manage/storages/breeds";

export const getStorageDetailsUrl = (pk: number) => `${manageStoragesUrl}/${pk}`;
export const getStorageEditUrl = (pk: number) => `${manageStoragesUrl}/${pk}/edit`;
export const getStorageCreateStoredItemInboundUrl = (pk: number) => `${manageStoragesUrl}/${pk}/inbound/new`;

export const getStorageBreedDetailsUrl = (pk: number) => `${manageStorageBreedsUrl}/${pk}`;
export const getStorageBreedEditUrl = (pk: number) => `${manageStorageBreedsUrl}/${pk}/edit`;

export const importStoredItemsUrl = `${manageStoragesUrl}/import`;
export const newStorageUrl = `${manageStoragesUrl}/new`;
export const newStorageBreedUrl = `${manageStorageBreedsUrl}/new`;
