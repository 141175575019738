import { ReactNode } from "react";
import BusyFeedback from "./BusyFeedback";

interface BusyFeedbackPageProps {
  label?: ReactNode | string;
}

const BusyFeedbackPage = ({ label }: BusyFeedbackPageProps) => (
  <div className="BusyFeedbackPage content-full">
    <div className="panel panel-widget">
      <div className="panel-body">
        <BusyFeedback label={label} />
      </div>
    </div>
  </div>
);

export default BusyFeedbackPage;
